import React, { useState, useMemo } from "react";
import { useSnackbar } from "notistack";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Chip from "@mui/material/Chip";

import TypeToConfirmModal from "../TypeToConfirmModal";
import ManageTagsModal from "../ManageTagsModal";
import { DeviceTag, FwMetadata } from "../../types";
import { AdminNetUtils } from "../../networking";

import "./FwVersions.scss";

interface FocusedFwMetadata {
  anchorEl: EventTarget & HTMLButtonElement;
  metadata: FwMetadata;
}

const CONFIRMATION_KEY = "Confirm Rollback";

export interface FwVersionsProps {
  metadatas: FwMetadata[];
  tags: DeviceTag[];
  onTagsModified: () => Promise<void> | void;
  doDeploy: (metadata: FwMetadata) => void;
  doRollback: (rollbackVersion: string) => Promise<void>;
  onTagsAssigned: () => Promise<void> | void;
  count: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}
export default function FwVersions(props: FwVersionsProps): React.ReactElement {
  const {
    metadatas,
    tags,
    onTagsModified,
    doDeploy,
    doRollback,
    onTagsAssigned,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [actionMenuFocusedFwMetadata, setActionMenuFocusedFwMetadata] =
    useState<FocusedFwMetadata | null>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [rollbackVersion, setRollbackVersion] = useState<string | null>(null);
  const [confirmationInput, setConfirmationInput] = useState<string>("");
  const [tagMgrOpen, setTagMgrOpen] = useState(false);
  const [filterTagsFwMetadata, setFilterTagsFwMetadata] =
    useState<FwMetadata | null>(null);

  function actionMenuClickHdlr(metadata: FwMetadata) {
    return function (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      setActionMenuFocusedFwMetadata({
        anchorEl: evt.currentTarget,
        metadata,
      });
    };
  }

  function actionMenuCloseHdlr() {
    setActionMenuFocusedFwMetadata(null);
  }

  function rollbackClickHdlr(metadata: FwMetadata) {
    setOpenConfirm(true);
    setRollbackVersion(metadata.version);
    setConfirmationInput(
      `You are about to perform a rollback on version: ${metadata.version}. Enter '${CONFIRMATION_KEY}' to proceed:`
    );
  }

  async function rollbackSuccessHdlr() {
    if (rollbackVersion) {
      try {
        await doRollback(rollbackVersion);
        enqueueSnackbar("Rollback completed", { variant: "success" });
        setOpenConfirm(false);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
  }

  function rollbackErrorHdlr() {
    enqueueSnackbar("Invalid entry", { variant: "error" });
  }

  function setFilterTagsClickHdlr(fwMetadata: FwMetadata) {
    setFilterTagsFwMetadata(fwMetadata);
    setTagMgrOpen(true);
  }

  async function tagsAssignedHdlr(selectedTagIds: number[]) {
    if (filterTagsFwMetadata === null) {
      return;
    }

    try {
      await AdminNetUtils.postFirmwareBinaryUpdateTagsFilter(
        filterTagsFwMetadata.version,
        selectedTagIds.length === 0 ? null : selectedTagIds
      );

      await onTagsAssigned();
      enqueueSnackbar("Updated tags", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function tagUnassignedHdlr(tgtVersion: string, tagId: number) {
    const prevFwMetadata = metadatas.find((m) => m.version === tgtVersion);
    if (prevFwMetadata === undefined) {
      return;
    }

    const newTagIds =
      prevFwMetadata.tagsFilter?.filter((tId) => tId !== tagId) || [];
    try {
      await AdminNetUtils.postFirmwareBinaryUpdateTagsFilter(
        tgtVersion,
        newTagIds.length === 0 ? null : newTagIds
      );

      await onTagsAssigned();
      enqueueSnackbar("Updated tags", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const dataFmt = useMemo(() => {
    const nextMetadatas = [...metadatas];
    nextMetadatas.sort((a: FwMetadata, b: FwMetadata) => {
      const aVersion = a.version || "";
      const bVersion = b.version || "";

      if (aVersion < bVersion) {
        return 1;
      }
      if (aVersion > bVersion) {
        return -1;
      }
      return 0;
    });
    return nextMetadatas;
  }, [metadatas]);

  const initSelectedTagIds = useMemo(() => {
    if (filterTagsFwMetadata === null) {
      return [];
    }

    return filterTagsFwMetadata.tagsFilter || [];
  }, [filterTagsFwMetadata]);

  const tagsMap = useMemo(() => {
    return tags.reduce((acc: Record<number, DeviceTag>, curr: DeviceTag) => {
      acc[curr.tagId] = curr;
      return acc;
    }, {});
  }, [tags]);

  return (
    <TableContainer component={Paper}>
      <Table className="fw-version-metadatas-table">
        <TableHead>
          <TableRow>
            <TableCell>Version</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Tags Filter</TableCell>
            <TableCell classes={{ root: "menu-col" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {dataFmt.map((row: FwMetadata) => (
            <TableRow key={row.version}>
              <TableCell>{row.version || "<empty>"}</TableCell>
              <TableCell>{row.size}</TableCell>
              <TableCell>
                <div>
                  {row.tagsFilter?.map((tagId) => {
                    const tag = tagsMap[tagId];
                    if (tag === undefined) {
                      return (
                        <Chip
                          key={tagId}
                          label={`<UD ${tagId}>`}
                          onDelete={() => tagUnassignedHdlr(row.version, tagId)}
                        />
                      );
                    }
                    return (
                      <Chip
                        key={tag.tagId}
                        label={tag.label}
                        onDelete={() =>
                          tagUnassignedHdlr(row.version, tag.tagId)
                        }
                      />
                    );
                  })}
                </div>
              </TableCell>

              <TableCell classes={{ root: "menu-col" }}>
                <IconButton onClick={actionMenuClickHdlr(row)}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(evt, next: number) => setPage(next)}
        onRowsPerPageChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          setRowsPerPage(Number.parseInt(evt.target.value, 10))
        }
      />
      <Menu
        anchorEl={actionMenuFocusedFwMetadata?.anchorEl}
        keepMounted
        open={actionMenuFocusedFwMetadata !== null}
        onClose={actionMenuCloseHdlr}
      >
        <MenuItem
          onClick={() => doDeploy(actionMenuFocusedFwMetadata.metadata)}
        >
          Deploy
        </MenuItem>
        <MenuItem
          onClick={() =>
            rollbackClickHdlr(actionMenuFocusedFwMetadata.metadata)
          }
        >
          Rollback
        </MenuItem>
        <MenuItem
          onClick={() =>
            setFilterTagsClickHdlr(actionMenuFocusedFwMetadata.metadata)
          }
        >
          Set Filter Tags
        </MenuItem>
      </Menu>
      <TypeToConfirmModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        onSuccess={rollbackSuccessHdlr}
        onFail={rollbackErrorHdlr}
        confirmationMessage={confirmationInput}
        confirmationKey={CONFIRMATION_KEY}
      />
      <ManageTagsModal
        tags={tags}
        open={tagMgrOpen}
        setOpen={setTagMgrOpen}
        onTagsAssigned={tagsAssignedHdlr}
        onTagsModified={onTagsModified}
        initSelectedTagIds={initSelectedTagIds}
      />
    </TableContainer>
  );
}
