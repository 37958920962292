import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import Axios, { CancelTokenSource } from "axios";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import fmtDate from "date-fns/format";
import { useSnackbar } from "notistack";

import { AdminNetUtils, DeviceNetUtils } from "../../networking";
import { CoredumpMetadata, DeviceMetadata, ColQueryOp } from "../../types";
import Loading from "../../components/Loading";

import "./Coredumps.scss";

const DATE_FMT = "MMM d, yyyy";
const TIME_FMT = "H:mm:ss";

function cancelReq(ref: MutableRefObject<CancelTokenSource>) {
  if (ref.current !== null) {
    ref.current.cancel();
  }
  ref.current = Axios.CancelToken.source();
}

export default function Coredumps(): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [coredumpsData, setCoredumpsData] = useState<CoredumpMetadata[]>([]);
  const [coredumpsCount, setCoredumpsCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [devices, setDevices] = useState<Record<string, DeviceMetadata>>({});
  const [deviceIdQuery, setDeviceIdQuery] = useState<string>("");
  const [deviceIdQueryComitted, setDeviceIdQueryComitted] =
    useState<string>("");
  const coredumpsCTSRef = useRef<CancelTokenSource | null>(null);
  const coredumpsCountCTSRef = useRef<CancelTokenSource | null>(null);
  const devicesCTSRef = useRef<CancelTokenSource | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  async function fetchPage() {
    cancelReq(coredumpsCTSRef);
    cancelReq(coredumpsCountCTSRef);
    cancelReq(devicesCTSRef);

    setLoading(true);

    try {
      const nextCoredumps = await AdminNetUtils.getCoredumps(
        deviceIdQueryComitted || undefined,
        page,
        rowsPerPage,
        coredumpsCTSRef.current,
      );
      const nextCoredumpsCount = await AdminNetUtils.getCoredumpsCount(
        undefined,
        coredumpsCountCTSRef.current,
      );
      if (nextCoredumps === null || nextCoredumpsCount === null) {
        return;
      }
      setCoredumpsData(nextCoredumps);
      setCoredumpsCount(nextCoredumpsCount);

      const deviceIds = new Set(nextCoredumps.map((cd) => cd.deviceId));
      const nextDevices = await DeviceNetUtils.getDevices({
        deviceId: { value: Array.from(deviceIds), op: ColQueryOp.Eq },
      });
      const nextDevicesMap = nextDevices.reduce(
        (acc: Record<string, DeviceMetadata>, curr: DeviceMetadata) => {
          acc[curr.deviceId] = curr;
          return acc;
        },
        {},
      );
      setDevices(nextDevicesMap);

      setLoading(false);
    } catch (error) {
      if (Axios.isCancel(error)) {
        return;
      }

      setLoading(false);

      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  function handleRowClick(coredump: CoredumpMetadata) {
    AdminNetUtils.getCoredump(coredump.deviceId, coredump.coredumpId);
  }

  useEffect(() => {
    fetchPage();
  }, [rowsPerPage, page, deviceIdQueryComitted]);

  function handleQuerySubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    setDeviceIdQueryComitted(deviceIdQuery);
  }

  return (
    <div className="coredumps-overview">
      <div className="query-row">
        <form className="inner" onSubmit={handleQuerySubmit}>
          <TextField
            classes={{ root: "query-input" }}
            placeholder="Search device id..."
            value={deviceIdQuery}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(evt: any) => setDeviceIdQuery(evt.target.value)}
          />
          <Button type="submit" variant="contained" color="primary">
            Query
          </Button>
        </form>
      </div>
      <Loading loading={loading} loadingClassName="coredumps-loading">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell align="right">Coredump ID</TableCell>
                <TableCell align="right">Datetime</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coredumpsData.map((dataRow) => (
                <TableRow
                  classes={{ root: "device-row" }}
                  key={dataRow.deviceId}
                  hover
                  onClick={() => handleRowClick(dataRow)}
                  role="button"
                >
                  <TableCell>{dataRow.deviceId}</TableCell>
                  <TableCell>
                    {devices[dataRow.deviceId]?.name || "Not found"}
                  </TableCell>
                  <TableCell align="right">{dataRow.coredumpId}</TableCell>
                  <TableCell align="right">
                    {`${fmtDate(dataRow.createdAt, DATE_FMT)} at ${fmtDate(
                      dataRow.createdAt,
                      TIME_FMT,
                    )}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Loading>
      <TablePagination
        component="div"
        classes={{ root: "pagination" }}
        rowsPerPageOptions={[10, 25]}
        count={coredumpsCount}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(evt) =>
          setRowsPerPage(parseInt(evt.target.value, 10))
        }
        page={page}
        onPageChange={(evt, nextPage) => setPage(nextPage)}
      />
    </div>
  );
}
