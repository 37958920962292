import React from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

export interface AuthUserAttributes {
  email: string;
  email_verified: boolean;
  sub: string;
}
export interface AuthToken {
  jwtToken: string;
  payload?: Record<string, string | number>;
}
export interface AuthUserSession {
  accessToken: AuthToken;
  refreshToken: AuthToken;
  clockDrift: number;
}
export interface AuthUser {
  attributes: AuthUserAttributes;
  signInUserSesson: AuthUserSession;
  authenticationFlowType: string;
  username: string;
}

export default function withAuth<Props>(
  AuthedComponent: React.FunctionComponent<Props>,
): React.FunctionComponent<Props> {
  return function WithAuth(props: Props) {
    const { user: authUser, authStatus } = useAuthenticator((context) => [
      context.user,
      context.authStatus,
    ]);

    return authStatus === "authenticated" && authUser !== null ? (
      <AuthedComponent {...props} />
    ) : (
      <Authenticator>
        {({ signOut, user }) => (
          <main>
            <h1>Hello {user.username}</h1>
            <button onClick={signOut}>Sign out</button>
          </main>
        )}
      </Authenticator>
    );
  };
}
