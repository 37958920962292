interface CfgEntry {
  num: number;
  key: string;
  value: string | null;
}

function parseSdkConfigLine(lineNum: number, lineRaw: string): CfgEntry | null {
  const line = lineRaw.trim();

  if (line[0] === "#") {
    return null;
  }

  const parts = line.split("=");

  const key = parts[0].trim();
  if (!key) {
    return null;
  }

  let value = parts[1] || null;
  if (value) {
    value = value.trim();
    if (value[0] === "\"") {
      value = value.slice(1);
    }
    if (value[value.length - 1] === "\"") {
      value = value.slice(0, value.length - 1);
    }
  }

  return {
    num: lineNum,
    key,
    value,
  };
}

function fmtValError(
  key: string,
  actual: string | null,
  expected: string,
  line: number
): string {
  return `Error at line ${line}: "${key}" expected to be "${expected}", was "${actual}"`;
}

function fmtMissingError(key: string): string {
  return `Error: missing "${key}"`;
}

export function parseSdkConfig(content: string): Record<string, CfgEntry> {
  const lines = content.split("\n");
  const res: Record<string, CfgEntry> = {};
  lines.forEach((line: string, i: number) => {
    const entry = parseSdkConfigLine(i + 1, line);
    if (entry === null) {
      return;
    }
    res[entry.key] = entry;
  });
  return res;
}

export default function validateSdkConfig(
  cfg: Record<string, CfgEntry>,
  cfgReq: Record<string, string>
): string[] {
  const visitedKeys = new Set<string>();
  const errors: string[] = [];

  Object.values(cfg).forEach((entry: CfgEntry) => {
    if (!Object.prototype.hasOwnProperty.call(cfgReq, entry.key)) {
      return;
    }

    visitedKeys.add(entry.key);

    if (entry.value !== cfgReq[entry.key]) {
      errors.push(
        fmtValError(entry.key, entry.value, cfgReq[entry.key], entry.num)
      );
    }
  });

  const cfgReqKeys = Object.keys(cfgReq);
  if (cfgReqKeys.length === visitedKeys.size) {
    return errors;
  }

  cfgReqKeys.forEach((key: string) => {
    if (!visitedKeys.has(key)) {
      // If we want this to be false, ommiting the cfg entry achieves this.
      if (cfgReq[key] === "n") {
        return;
      }
      errors.push(fmtMissingError(key));
    }
  });

  return errors;
}

export async function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (evt) => {
      if (evt.target === null) {
        return reject(new Error("Null result"));
      }
      const text = evt.target.result as string;
      return resolve(text);
    };
    reader.onerror = () => reject(reader.error);

    reader.readAsText(file);
  });
}

export function validateVersion(version: string): string | null {
  const valid = version.match(/^\d+\.\d+\.\d+\.\d+$/);
  if (valid === null) {
    return "Invalid";
  }

  return null;
}
