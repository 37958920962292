import * as CvrtCase from "change-case";

import * as UvxZenerCapnp from "./capnp/ts_gen/uvx_zener.capnp";

export function zenerStateCapnpToStr(
  zenerState: UvxZenerCapnp.UvxZenerState,
): string {
  switch (zenerState) {
    case UvxZenerCapnp.UvxZenerState.START:
      return "START";
    case UvxZenerCapnp.UvxZenerState.INITIALIZING:
      return "INITIALIZING";
    case UvxZenerCapnp.UvxZenerState.UNCONFIGURED:
      return "UNCONFIGURED";
    case UvxZenerCapnp.UvxZenerState.ENABLED:
      return "ENABLED";
    case UvxZenerCapnp.UvxZenerState.DISABLED:
      return "DISABLED";
    case UvxZenerCapnp.UvxZenerState.OTA_UPDATING:
      return "OTA_UPDATING";
    case UvxZenerCapnp.UvxZenerState.ERROR:
      return "ERROR";
    case UvxZenerCapnp.UvxZenerState.RESTART:
      return "RESTART";
    default:
      return "INVALID";
  }
}

function cvrtObjKeys<I = object, O = object>(
  obj: I,
  cvrter: (inp: string) => string,
): O {
  return Object.entries(obj).reduce(function (
    acc: object,
    [key, val]: [string, any], // eslint-disable-line @typescript-eslint/no-explicit-any
  ) {
    const cvrtKey: string = cvrter(key);
    acc[cvrtKey] = val;
    return acc;
  }, {}) as O;
}

export function keysToCamelCase<I = object, O = object>(inp: I): O;
export function keysToCamelCase<I = object, O = object>(inp: I[]): O[];
export function keysToCamelCase<I = object, O = object>(inp: I[] | I): O[] | O {
  if (Array.isArray(inp)) {
    return <O[]>(<I[]>inp).map((i: I) => cvrtObjKeys(i, CvrtCase.camelCase));
  }

  return <O>cvrtObjKeys(<I>inp, CvrtCase.camelCase);
}

export function keysToSnakeCase<I = object, O = object>(inp: I): O;
export function keysToSnakeCase<I = object, O = object>(inp: I[]): O[];
export function keysToSnakeCase<I = object, O = object>(inp: I[] | I): O[] | O {
  if (Array.isArray(inp)) {
    return <O[]>(<I[]>inp).map((i: I) => cvrtObjKeys(i, CvrtCase.snakeCase));
  }

  return <O>cvrtObjKeys(<I>inp, CvrtCase.snakeCase);
}
