import React, { useState } from "react";
import { Portal } from "react-portal";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

import { TagsNetUtils } from "../../networking";

import "./CreateTagModal.scss";

export interface CreateTagModalProps {
  open: boolean;
  setOpen: (nextOpen: boolean) => void;
  onTagCreated?: () => void | Promise<void>;
}
export default function CreateTagModal(props: CreateTagModalProps) {
  const { open, setOpen, onTagCreated } = props;

  const [label, setLabel] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  function closeModal() {
    setLabel("");
    setOpen(false);
  }

  function labelChangeHdlr(e: React.ChangeEvent<HTMLInputElement>) {
    setLabel(e.target.value);
  }

  async function createTag() {
    if (!label) {
      return;
    }

    try {
      await TagsNetUtils.createTags([{ label }]);
      closeModal();
      if (onTagCreated) {
        await onTagCreated();
      }
      enqueueSnackbar("Tag created!", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className="create-tag-modal-container">
        <div className="background" onClick={() => closeModal()} />
        <Paper classes={{ root: "content" }}>
          <h3 className="header">Tag Search</h3>
          <TextField
            value={label}
            onChange={labelChangeHdlr}
            placeholder="New tag label"
            variant="standard"
          />
          <div className="action-row">
            <Button onClick={() => closeModal()}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={createTag}>
              Create
            </Button>
          </div>
        </Paper>
      </div>
    </Portal>
  );
}
