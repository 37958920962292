import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

import ManageTagsModal from "../ManageTagsModal";
import { DeviceMetadata, DeviceTag } from "../../types";
import { DeviceNetUtils } from "../../networking";

import "./FleetOverviewToolbar.scss";

export interface FleetOverviewToolbarProps {
  selectedDeviceIds: string[];
  fetchDevices: () => Promise<void>;
  fetchTags: () => Promise<void>;
  tags: DeviceTag[];
  devices: DeviceMetadata[];
}
export default function FleetOverviewToolbar(props: FleetOverviewToolbarProps) {
  const { selectedDeviceIds, fetchDevices, fetchTags, tags, devices } = props;

  const [tagMgrModalOpen, setTagMgrModalOpen] = useState(false);
  const [initSelectedTagIds, setInitSelectedTagIds] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const canAssign = selectedDeviceIds.length > 0;

  async function assignTags(selectedTagIds: number[]) {
    const params = selectedDeviceIds.map((deviceId) => ({
      deviceId,
      tagIds: selectedTagIds,
    }));

    try {
      await DeviceNetUtils.setDeviceTags(params);
      setTagMgrModalOpen(false);
      enqueueSnackbar("Tags assigned", { variant: "success" });
      await fetchDevices();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  function openTagMgrModalClickHdlr() {
    if (selectedDeviceIds.length !== 1) {
      setInitSelectedTagIds([]);
    } else {
      const device = devices.find((d) => d.deviceId === selectedDeviceIds[0]);
      if (device !== undefined) {
        setInitSelectedTagIds(device.tagIds);
      }
    }

    setTagMgrModalOpen(true);
  }

  return (
    <>
      <div className="fleet-overview-toolbar">
        <Button variant="contained" color="primary" onClick={fetchDevices}>
          Reload
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={openTagMgrModalClickHdlr}
        >
          {canAssign ? "Assign tags" : "Manage tags"}
        </Button>
      </div>
      <ManageTagsModal
        tags={tags}
        open={tagMgrModalOpen}
        setOpen={setTagMgrModalOpen}
        onTagsModified={fetchTags}
        onTagsAssigned={assignTags}
        initSelectedTagIds={initSelectedTagIds}
      />
    </>
  );
}
