import React, { useState } from "react";
import { Portal } from "react-portal";
import "./TypeToConfirmModal.css";

interface TypeToConfirmModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => Promise<void>;
  onFail: () => void;
  confirmationMessage: string;
  confirmationKey: string;
}

export default function TypeToConfirmModal(
  props: TypeToConfirmModalProps,
): React.ReactElement | null {
  const { 
    open, 
    setOpen,
    onSuccess,
    onFail,
    confirmationMessage,
    confirmationKey,
  } = props; 
  const [confirmationText, setConfirmationText] = useState<string>("");

  async function handleSubmit(evt?: React.FormEvent<HTMLFormElement>) {
    if (evt !== undefined) {
      evt.preventDefault();
    }

    if (confirmationText === confirmationKey) {
      onSuccess();
      return;
    }
    onFail();
  }

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className="confirmation-modal-container">
        <div
          className="confirmation-modal-overlay"
          aria-hidden="true"
          role="button"
          onClick={() => setOpen(false)}
        />
        <div className="confirmation-modal">
          <div className="confirmation-modal-titlespace">
            <div className="confirmation-modal-titlespace-span-container">
              <span className="confirmation-modal-titlespace-span">CONFIRM ACTION</span>
            </div>
            <button
              className="confirmation-modal-close"
              type="button"
              onClick={() => setOpen(false)}
            >
              X
            </button>
          </div>
          <div className="confirmation-modal-input-container">
            <span className="confirmation-modal-heading">
              {confirmationMessage}
            </span>
            <input
              className="confirmation-modal-input-field"
              type="text"
              value={confirmationText}
              onChange={(evt) => setConfirmationText(evt.target.value)}
            />
          </div>
          <div className="confirmation--modal-action">
            <button
              className="confirmation-modal-button"
              type="button"
              onClick={() => handleSubmit()}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
}
