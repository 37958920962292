/* tslint:disable */
/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */
import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
import { UvxFwVersion, UvxProvisionEvt } from "./uvx.capnp.js";
import { UvxOtaFwMetadata } from "./uvx_ota.capnp.js";
export const _capnpFileId = BigInt("0xee5d995a9b61b96f");
export var UvxZenerState;
(function (UvxZenerState) {
    UvxZenerState[UvxZenerState["START"] = 0] = "START";
    UvxZenerState[UvxZenerState["INITIALIZING"] = 1] = "INITIALIZING";
    UvxZenerState[UvxZenerState["UNCONFIGURED"] = 2] = "UNCONFIGURED";
    UvxZenerState[UvxZenerState["ENABLED"] = 3] = "ENABLED";
    UvxZenerState[UvxZenerState["DISABLED"] = 4] = "DISABLED";
    UvxZenerState[UvxZenerState["OTA_UPDATING"] = 5] = "OTA_UPDATING";
    UvxZenerState[UvxZenerState["ERROR"] = 6] = "ERROR";
    UvxZenerState[UvxZenerState["RESTART"] = 7] = "RESTART";
})(UvxZenerState || (UvxZenerState = {}));
export class UvxZenerCtrl extends __S {
    getLampEn() { return __S.getUint16(0, this, UvxZenerCtrl._capnp.defaultLampEn); }
    setLampEn(value) { __S.setUint16(0, value, this, UvxZenerCtrl._capnp.defaultLampEn); }
    getLampOpMode() { return __S.getUint16(2, this); }
    setLampOpMode(value) { __S.setUint16(2, value, this); }
    getHeight() { return __S.getFloat32(4, this); }
    setHeight(value) { __S.setFloat32(4, value, this); }
    toString() { return "UvxZenerCtrl_" + super.toString(); }
}
UvxZenerCtrl._capnp = { displayName: "UvxZenerCtrl", id: "cf2d610d3dc2443b", size: new __O(8, 0), defaultLampEn: capnp.getUint16Mask(0) };
export class UvxZenerStatus extends __S {
    getTsMs() { return __S.getUint64(0, this); }
    setTsMs(value) { __S.setUint64(0, value, this); }
    getOcc() { return __S.getBit(64, this); }
    setOcc(value) { __S.setBit(64, value, this); }
    getLampTrigd() { return __S.getBit(65, this); }
    setLampTrigd(value) { __S.setBit(65, value, this); }
    getLampFiring() { return __S.getBit(66, this); }
    setLampFiring(value) { __S.setBit(66, value, this); }
    getState() { return __S.getUint16(10, this); }
    setState(value) { __S.setUint16(10, value, this); }
    toString() { return "UvxZenerStatus_" + super.toString(); }
}
UvxZenerStatus._capnp = { displayName: "UvxZenerStatus", id: "d8415f18aae2bb04", size: new __O(16, 0) };
export var UvxZenerEvt_Evt_Which;
(function (UvxZenerEvt_Evt_Which) {
    UvxZenerEvt_Evt_Which[UvxZenerEvt_Evt_Which["STATUS_EVT"] = 0] = "STATUS_EVT";
    UvxZenerEvt_Evt_Which[UvxZenerEvt_Evt_Which["TEST_EVT"] = 1] = "TEST_EVT";
    UvxZenerEvt_Evt_Which[UvxZenerEvt_Evt_Which["PROVISION_EVT"] = 2] = "PROVISION_EVT";
})(UvxZenerEvt_Evt_Which || (UvxZenerEvt_Evt_Which = {}));
export class UvxZenerEvt_Evt extends __S {
    adoptStatusEvt(value) {
        __S.setUint16(0, 0, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownStatusEvt() { return __S.disown(this.getStatusEvt()); }
    getStatusEvt() {
        __S.testWhich("statusEvt", __S.getUint16(0, this), 0, this);
        return __S.getStruct(0, UvxZenerStatus, this);
    }
    hasStatusEvt() { return !__S.isNull(__S.getPointer(0, this)); }
    initStatusEvt() {
        __S.setUint16(0, 0, this);
        return __S.initStructAt(0, UvxZenerStatus, this);
    }
    isStatusEvt() { return __S.getUint16(0, this) === 0; }
    setStatusEvt(value) {
        __S.setUint16(0, 0, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    adoptTestEvt(value) {
        __S.setUint16(0, 1, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownTestEvt() { return __S.disown(this.getTestEvt()); }
    getTestEvt() {
        __S.testWhich("testEvt", __S.getUint16(0, this), 1, this);
        return __S.getStruct(0, UvxZenerTestEvt, this);
    }
    hasTestEvt() { return !__S.isNull(__S.getPointer(0, this)); }
    initTestEvt() {
        __S.setUint16(0, 1, this);
        return __S.initStructAt(0, UvxZenerTestEvt, this);
    }
    isTestEvt() { return __S.getUint16(0, this) === 1; }
    setTestEvt(value) {
        __S.setUint16(0, 1, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    adoptProvisionEvt(value) {
        __S.setUint16(0, 2, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownProvisionEvt() { return __S.disown(this.getProvisionEvt()); }
    getProvisionEvt() {
        __S.testWhich("provisionEvt", __S.getUint16(0, this), 2, this);
        return __S.getStruct(0, UvxProvisionEvt, this);
    }
    hasProvisionEvt() { return !__S.isNull(__S.getPointer(0, this)); }
    initProvisionEvt() {
        __S.setUint16(0, 2, this);
        return __S.initStructAt(0, UvxProvisionEvt, this);
    }
    isProvisionEvt() { return __S.getUint16(0, this) === 2; }
    setProvisionEvt(value) {
        __S.setUint16(0, 2, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    toString() { return "UvxZenerEvt_Evt_" + super.toString(); }
    which() { return __S.getUint16(0, this); }
}
UvxZenerEvt_Evt.STATUS_EVT = UvxZenerEvt_Evt_Which.STATUS_EVT;
UvxZenerEvt_Evt.TEST_EVT = UvxZenerEvt_Evt_Which.TEST_EVT;
UvxZenerEvt_Evt.PROVISION_EVT = UvxZenerEvt_Evt_Which.PROVISION_EVT;
UvxZenerEvt_Evt._capnp = { displayName: "evt", id: "d6b9fd8eac985293", size: new __O(8, 1) };
export class UvxZenerEvt extends __S {
    getEvt() { return __S.getAs(UvxZenerEvt_Evt, this); }
    initEvt() { return __S.getAs(UvxZenerEvt_Evt, this); }
    toString() { return "UvxZenerEvt_" + super.toString(); }
}
UvxZenerEvt._capnp = { displayName: "UvxZenerEvt", id: "bce89f8d4b21c6d2", size: new __O(8, 1) };
export var UvxZenerCmd_Cmd_Which;
(function (UvxZenerCmd_Cmd_Which) {
    UvxZenerCmd_Cmd_Which[UvxZenerCmd_Cmd_Which["CTRL"] = 0] = "CTRL";
    UvxZenerCmd_Cmd_Which[UvxZenerCmd_Cmd_Which["SELF_TEST"] = 1] = "SELF_TEST";
    UvxZenerCmd_Cmd_Which[UvxZenerCmd_Cmd_Which["RESET"] = 2] = "RESET";
    UvxZenerCmd_Cmd_Which[UvxZenerCmd_Cmd_Which["OTA_FW_METADATA"] = 3] = "OTA_FW_METADATA";
})(UvxZenerCmd_Cmd_Which || (UvxZenerCmd_Cmd_Which = {}));
export class UvxZenerCmd_Cmd extends __S {
    adoptCtrl(value) {
        __S.setUint16(0, 0, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownCtrl() { return __S.disown(this.getCtrl()); }
    getCtrl() {
        __S.testWhich("ctrl", __S.getUint16(0, this), 0, this);
        return __S.getStruct(0, UvxZenerCtrl, this);
    }
    hasCtrl() { return !__S.isNull(__S.getPointer(0, this)); }
    initCtrl() {
        __S.setUint16(0, 0, this);
        return __S.initStructAt(0, UvxZenerCtrl, this);
    }
    isCtrl() { return __S.getUint16(0, this) === 0; }
    setCtrl(value) {
        __S.setUint16(0, 0, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    isSelfTest() { return __S.getUint16(0, this) === 1; }
    setSelfTest() { __S.setUint16(0, 1, this); }
    isReset() { return __S.getUint16(0, this) === 2; }
    setReset() { __S.setUint16(0, 2, this); }
    adoptOtaFwMetadata(value) {
        __S.setUint16(0, 3, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownOtaFwMetadata() { return __S.disown(this.getOtaFwMetadata()); }
    getOtaFwMetadata() {
        __S.testWhich("otaFwMetadata", __S.getUint16(0, this), 3, this);
        return __S.getStruct(0, UvxOtaFwMetadata, this);
    }
    hasOtaFwMetadata() { return !__S.isNull(__S.getPointer(0, this)); }
    initOtaFwMetadata() {
        __S.setUint16(0, 3, this);
        return __S.initStructAt(0, UvxOtaFwMetadata, this);
    }
    isOtaFwMetadata() { return __S.getUint16(0, this) === 3; }
    setOtaFwMetadata(value) {
        __S.setUint16(0, 3, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    toString() { return "UvxZenerCmd_Cmd_" + super.toString(); }
    which() { return __S.getUint16(0, this); }
}
UvxZenerCmd_Cmd.CTRL = UvxZenerCmd_Cmd_Which.CTRL;
UvxZenerCmd_Cmd.SELF_TEST = UvxZenerCmd_Cmd_Which.SELF_TEST;
UvxZenerCmd_Cmd.RESET = UvxZenerCmd_Cmd_Which.RESET;
UvxZenerCmd_Cmd.OTA_FW_METADATA = UvxZenerCmd_Cmd_Which.OTA_FW_METADATA;
UvxZenerCmd_Cmd._capnp = { displayName: "cmd", id: "b9ebde32642c0cd2", size: new __O(8, 1) };
export class UvxZenerCmd extends __S {
    getCmd() { return __S.getAs(UvxZenerCmd_Cmd, this); }
    initCmd() { return __S.getAs(UvxZenerCmd_Cmd, this); }
    toString() { return "UvxZenerCmd_" + super.toString(); }
}
UvxZenerCmd._capnp = { displayName: "UvxZenerCmd", id: "b76f81d1befc0907", size: new __O(8, 1) };
export var UvxZenerGlobalCmd_Cmd_Which;
(function (UvxZenerGlobalCmd_Cmd_Which) {
    UvxZenerGlobalCmd_Cmd_Which[UvxZenerGlobalCmd_Cmd_Which["ROLLBACK"] = 0] = "ROLLBACK";
    UvxZenerGlobalCmd_Cmd_Which[UvxZenerGlobalCmd_Cmd_Which["OTA_FW_METADATA"] = 1] = "OTA_FW_METADATA";
})(UvxZenerGlobalCmd_Cmd_Which || (UvxZenerGlobalCmd_Cmd_Which = {}));
export class UvxZenerGlobalCmd_Cmd extends __S {
    adoptRollback(value) {
        __S.setUint16(0, 0, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownRollback() { return __S.disown(this.getRollback()); }
    getRollback() {
        __S.testWhich("rollback", __S.getUint16(0, this), 0, this);
        return __S.getStruct(0, UvxFwVersion, this);
    }
    hasRollback() { return !__S.isNull(__S.getPointer(0, this)); }
    initRollback() {
        __S.setUint16(0, 0, this);
        return __S.initStructAt(0, UvxFwVersion, this);
    }
    isRollback() { return __S.getUint16(0, this) === 0; }
    setRollback(value) {
        __S.setUint16(0, 0, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    adoptOtaFwMetadata(value) {
        __S.setUint16(0, 1, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownOtaFwMetadata() { return __S.disown(this.getOtaFwMetadata()); }
    getOtaFwMetadata() {
        __S.testWhich("otaFwMetadata", __S.getUint16(0, this), 1, this);
        return __S.getStruct(0, UvxOtaFwMetadata, this);
    }
    hasOtaFwMetadata() { return !__S.isNull(__S.getPointer(0, this)); }
    initOtaFwMetadata() {
        __S.setUint16(0, 1, this);
        return __S.initStructAt(0, UvxOtaFwMetadata, this);
    }
    isOtaFwMetadata() { return __S.getUint16(0, this) === 1; }
    setOtaFwMetadata(value) {
        __S.setUint16(0, 1, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    toString() { return "UvxZenerGlobalCmd_Cmd_" + super.toString(); }
    which() { return __S.getUint16(0, this); }
}
UvxZenerGlobalCmd_Cmd.ROLLBACK = UvxZenerGlobalCmd_Cmd_Which.ROLLBACK;
UvxZenerGlobalCmd_Cmd.OTA_FW_METADATA = UvxZenerGlobalCmd_Cmd_Which.OTA_FW_METADATA;
UvxZenerGlobalCmd_Cmd._capnp = { displayName: "cmd", id: "952731950df8648e", size: new __O(8, 1) };
export class UvxZenerGlobalCmd extends __S {
    getCmd() { return __S.getAs(UvxZenerGlobalCmd_Cmd, this); }
    initCmd() { return __S.getAs(UvxZenerGlobalCmd_Cmd, this); }
    toString() { return "UvxZenerGlobalCmd_" + super.toString(); }
}
UvxZenerGlobalCmd._capnp = { displayName: "UvxZenerGlobalCmd", id: "e0db790b3dd338a5", size: new __O(8, 1) };
export var UvxZenerTestType;
(function (UvxZenerTestType) {
    UvxZenerTestType[UvxZenerTestType["LAMP"] = 0] = "LAMP";
    UvxZenerTestType[UvxZenerTestType["RADAR"] = 1] = "RADAR";
    UvxZenerTestType[UvxZenerTestType["LED"] = 2] = "LED";
})(UvxZenerTestType || (UvxZenerTestType = {}));
export var UvxZenerTestCmd_Cmd_Which;
(function (UvxZenerTestCmd_Cmd_Which) {
    UvxZenerTestCmd_Cmd_Which[UvxZenerTestCmd_Cmd_Which["START"] = 0] = "START";
    UvxZenerTestCmd_Cmd_Which[UvxZenerTestCmd_Cmd_Which["STOP"] = 1] = "STOP";
})(UvxZenerTestCmd_Cmd_Which || (UvxZenerTestCmd_Cmd_Which = {}));
export class UvxZenerTestCmd_Cmd extends __S {
    getStart() {
        __S.testWhich("start", __S.getUint16(2, this), 0, this);
        return __S.getUint16(0, this);
    }
    isStart() { return __S.getUint16(2, this) === 0; }
    setStart(value) {
        __S.setUint16(2, 0, this);
        __S.setUint16(0, value, this);
    }
    isStop() { return __S.getUint16(2, this) === 1; }
    setStop() { __S.setUint16(2, 1, this); }
    toString() { return "UvxZenerTestCmd_Cmd_" + super.toString(); }
    which() { return __S.getUint16(2, this); }
}
UvxZenerTestCmd_Cmd.START = UvxZenerTestCmd_Cmd_Which.START;
UvxZenerTestCmd_Cmd.STOP = UvxZenerTestCmd_Cmd_Which.STOP;
UvxZenerTestCmd_Cmd._capnp = { displayName: "cmd", id: "e782ef70a2609214", size: new __O(8, 0) };
export class UvxZenerTestCmd extends __S {
    getCmd() { return __S.getAs(UvxZenerTestCmd_Cmd, this); }
    initCmd() { return __S.getAs(UvxZenerTestCmd_Cmd, this); }
    toString() { return "UvxZenerTestCmd_" + super.toString(); }
}
UvxZenerTestCmd._capnp = { displayName: "UvxZenerTestCmd", id: "d476fa9057b7a2ba", size: new __O(8, 0) };
export var UvxZenerTestEvt_Evt_Which;
(function (UvxZenerTestEvt_Evt_Which) {
    UvxZenerTestEvt_Evt_Which[UvxZenerTestEvt_Evt_Which["STARTED"] = 0] = "STARTED";
    UvxZenerTestEvt_Evt_Which[UvxZenerTestEvt_Evt_Which["FINISHED"] = 1] = "FINISHED";
    UvxZenerTestEvt_Evt_Which[UvxZenerTestEvt_Evt_Which["RADAR_TEST_EVT"] = 2] = "RADAR_TEST_EVT";
    UvxZenerTestEvt_Evt_Which[UvxZenerTestEvt_Evt_Which["LED_TEST_EVT"] = 3] = "LED_TEST_EVT";
})(UvxZenerTestEvt_Evt_Which || (UvxZenerTestEvt_Evt_Which = {}));
export class UvxZenerTestEvt_Evt extends __S {
    getStarted() {
        __S.testWhich("started", __S.getUint16(2, this), 0, this);
        return __S.getUint16(0, this);
    }
    isStarted() { return __S.getUint16(2, this) === 0; }
    setStarted(value) {
        __S.setUint16(2, 0, this);
        __S.setUint16(0, value, this);
    }
    adoptFinished(value) {
        __S.setUint16(2, 1, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownFinished() { return __S.disown(this.getFinished()); }
    getFinished() {
        __S.testWhich("finished", __S.getUint16(2, this), 1, this);
        return __S.getStruct(0, UvxZenerTestResult, this);
    }
    hasFinished() { return !__S.isNull(__S.getPointer(0, this)); }
    initFinished() {
        __S.setUint16(2, 1, this);
        return __S.initStructAt(0, UvxZenerTestResult, this);
    }
    isFinished() { return __S.getUint16(2, this) === 1; }
    setFinished(value) {
        __S.setUint16(2, 1, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    adoptRadarTestEvt(value) {
        __S.setUint16(2, 2, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownRadarTestEvt() { return __S.disown(this.getRadarTestEvt()); }
    getRadarTestEvt() {
        __S.testWhich("radarTestEvt", __S.getUint16(2, this), 2, this);
        return __S.getStruct(0, UvxZenerRadarTestEvt, this);
    }
    hasRadarTestEvt() { return !__S.isNull(__S.getPointer(0, this)); }
    initRadarTestEvt() {
        __S.setUint16(2, 2, this);
        return __S.initStructAt(0, UvxZenerRadarTestEvt, this);
    }
    isRadarTestEvt() { return __S.getUint16(2, this) === 2; }
    setRadarTestEvt(value) {
        __S.setUint16(2, 2, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    adoptLedTestEvt(value) {
        __S.setUint16(2, 3, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownLedTestEvt() { return __S.disown(this.getLedTestEvt()); }
    getLedTestEvt() {
        __S.testWhich("ledTestEvt", __S.getUint16(2, this), 3, this);
        return __S.getStruct(0, UvxZenerLedTestEvt, this);
    }
    hasLedTestEvt() { return !__S.isNull(__S.getPointer(0, this)); }
    initLedTestEvt() {
        __S.setUint16(2, 3, this);
        return __S.initStructAt(0, UvxZenerLedTestEvt, this);
    }
    isLedTestEvt() { return __S.getUint16(2, this) === 3; }
    setLedTestEvt(value) {
        __S.setUint16(2, 3, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    toString() { return "UvxZenerTestEvt_Evt_" + super.toString(); }
    which() { return __S.getUint16(2, this); }
}
UvxZenerTestEvt_Evt.STARTED = UvxZenerTestEvt_Evt_Which.STARTED;
UvxZenerTestEvt_Evt.FINISHED = UvxZenerTestEvt_Evt_Which.FINISHED;
UvxZenerTestEvt_Evt.RADAR_TEST_EVT = UvxZenerTestEvt_Evt_Which.RADAR_TEST_EVT;
UvxZenerTestEvt_Evt.LED_TEST_EVT = UvxZenerTestEvt_Evt_Which.LED_TEST_EVT;
UvxZenerTestEvt_Evt._capnp = { displayName: "evt", id: "b0e3daae3eba7819", size: new __O(8, 1) };
export class UvxZenerTestEvt extends __S {
    getEvt() { return __S.getAs(UvxZenerTestEvt_Evt, this); }
    initEvt() { return __S.getAs(UvxZenerTestEvt_Evt, this); }
    toString() { return "UvxZenerTestEvt_" + super.toString(); }
}
UvxZenerTestEvt._capnp = { displayName: "UvxZenerTestEvt", id: "c116b9b5defff50d", size: new __O(8, 1) };
export var UvxZenerTestResult_Result_Which;
(function (UvxZenerTestResult_Result_Which) {
    UvxZenerTestResult_Result_Which[UvxZenerTestResult_Result_Which["LAMP"] = 0] = "LAMP";
    UvxZenerTestResult_Result_Which[UvxZenerTestResult_Result_Which["RADAR"] = 1] = "RADAR";
    UvxZenerTestResult_Result_Which[UvxZenerTestResult_Result_Which["LED"] = 2] = "LED";
})(UvxZenerTestResult_Result_Which || (UvxZenerTestResult_Result_Which = {}));
export class UvxZenerTestResult_Result extends __S {
    adoptLamp(value) {
        __S.setUint16(0, 0, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownLamp() { return __S.disown(this.getLamp()); }
    getLamp() {
        __S.testWhich("lamp", __S.getUint16(0, this), 0, this);
        return __S.getStruct(0, UvxZenerLampTestResult, this);
    }
    hasLamp() { return !__S.isNull(__S.getPointer(0, this)); }
    initLamp() {
        __S.setUint16(0, 0, this);
        return __S.initStructAt(0, UvxZenerLampTestResult, this);
    }
    isLamp() { return __S.getUint16(0, this) === 0; }
    setLamp(value) {
        __S.setUint16(0, 0, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    adoptRadar(value) {
        __S.setUint16(0, 1, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownRadar() { return __S.disown(this.getRadar()); }
    getRadar() {
        __S.testWhich("radar", __S.getUint16(0, this), 1, this);
        return __S.getStruct(0, UvxZenerRadarTestResult, this);
    }
    hasRadar() { return !__S.isNull(__S.getPointer(0, this)); }
    initRadar() {
        __S.setUint16(0, 1, this);
        return __S.initStructAt(0, UvxZenerRadarTestResult, this);
    }
    isRadar() { return __S.getUint16(0, this) === 1; }
    setRadar(value) {
        __S.setUint16(0, 1, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    adoptLed(value) {
        __S.setUint16(0, 2, this);
        __S.adopt(value, __S.getPointer(0, this));
    }
    disownLed() { return __S.disown(this.getLed()); }
    getLed() {
        __S.testWhich("led", __S.getUint16(0, this), 2, this);
        return __S.getStruct(0, UvxZenerLedTestResult, this);
    }
    hasLed() { return !__S.isNull(__S.getPointer(0, this)); }
    initLed() {
        __S.setUint16(0, 2, this);
        return __S.initStructAt(0, UvxZenerLedTestResult, this);
    }
    isLed() { return __S.getUint16(0, this) === 2; }
    setLed(value) {
        __S.setUint16(0, 2, this);
        __S.copyFrom(value, __S.getPointer(0, this));
    }
    toString() { return "UvxZenerTestResult_Result_" + super.toString(); }
    which() { return __S.getUint16(0, this); }
}
UvxZenerTestResult_Result.LAMP = UvxZenerTestResult_Result_Which.LAMP;
UvxZenerTestResult_Result.RADAR = UvxZenerTestResult_Result_Which.RADAR;
UvxZenerTestResult_Result.LED = UvxZenerTestResult_Result_Which.LED;
UvxZenerTestResult_Result._capnp = { displayName: "result", id: "e0e440d95e049d28", size: new __O(8, 1) };
export class UvxZenerTestResult extends __S {
    getResult() { return __S.getAs(UvxZenerTestResult_Result, this); }
    initResult() { return __S.getAs(UvxZenerTestResult_Result, this); }
    toString() { return "UvxZenerTestResult_" + super.toString(); }
}
UvxZenerTestResult._capnp = { displayName: "UvxZenerTestResult", id: "86f0aee32cc3462b", size: new __O(8, 1) };
export class UvxZenerLampTestResult extends __S {
    getTimeToFireMs() { return __S.getUint32(0, this); }
    setTimeToFireMs(value) { __S.setUint32(0, value, this); }
    getTestTimeMs() { return __S.getUint32(4, this); }
    setTestTimeMs(value) { __S.setUint32(4, value, this); }
    getFiringPerc() { return __S.getFloat32(8, this); }
    setFiringPerc(value) { __S.setFloat32(8, value, this); }
    toString() { return "UvxZenerLampTestResult_" + super.toString(); }
}
UvxZenerLampTestResult._capnp = { displayName: "UvxZenerLampTestResult", id: "b7464e3cfd1569b5", size: new __O(16, 0) };
export class UvxZenerRadarTestResult extends __S {
    getVacantStdDev() { return __S.getFloat32(0, this); }
    setVacantStdDev(value) { __S.setFloat32(0, value, this); }
    getMotionAbsMax() { return __S.getUint16(4, this); }
    setMotionAbsMax(value) { __S.setUint16(4, value, this); }
    toString() { return "UvxZenerRadarTestResult_" + super.toString(); }
}
UvxZenerRadarTestResult._capnp = { displayName: "UvxZenerRadarTestResult", id: "80c3c2d2ab5d865a", size: new __O(8, 0) };
export var UvxZenerRadarTestEvt_Evt_Which;
(function (UvxZenerRadarTestEvt_Evt_Which) {
    UvxZenerRadarTestEvt_Evt_Which[UvxZenerRadarTestEvt_Evt_Which["VACANCY_TEST_START"] = 0] = "VACANCY_TEST_START";
    UvxZenerRadarTestEvt_Evt_Which[UvxZenerRadarTestEvt_Evt_Which["MOTION_TEST_START"] = 1] = "MOTION_TEST_START";
})(UvxZenerRadarTestEvt_Evt_Which || (UvxZenerRadarTestEvt_Evt_Which = {}));
export class UvxZenerRadarTestEvt_Evt extends __S {
    isVacancyTestStart() { return __S.getUint16(0, this) === 0; }
    setVacancyTestStart() { __S.setUint16(0, 0, this); }
    isMotionTestStart() { return __S.getUint16(0, this) === 1; }
    setMotionTestStart() { __S.setUint16(0, 1, this); }
    toString() { return "UvxZenerRadarTestEvt_Evt_" + super.toString(); }
    which() { return __S.getUint16(0, this); }
}
UvxZenerRadarTestEvt_Evt.VACANCY_TEST_START = UvxZenerRadarTestEvt_Evt_Which.VACANCY_TEST_START;
UvxZenerRadarTestEvt_Evt.MOTION_TEST_START = UvxZenerRadarTestEvt_Evt_Which.MOTION_TEST_START;
UvxZenerRadarTestEvt_Evt._capnp = { displayName: "evt", id: "ef1d990ea7b4f9ea", size: new __O(8, 0) };
export class UvxZenerRadarTestEvt extends __S {
    getEvt() { return __S.getAs(UvxZenerRadarTestEvt_Evt, this); }
    initEvt() { return __S.getAs(UvxZenerRadarTestEvt_Evt, this); }
    toString() { return "UvxZenerRadarTestEvt_" + super.toString(); }
}
UvxZenerRadarTestEvt._capnp = { displayName: "UvxZenerRadarTestEvt", id: "f13b0218a7ab1bd9", size: new __O(8, 0) };
export class UvxZenerLedTestResult extends __S {
    toString() { return "UvxZenerLedTestResult_" + super.toString(); }
}
UvxZenerLedTestResult._capnp = { displayName: "UvxZenerLedTestResult", id: "89f34f7fe514e0d5", size: new __O(0, 0) };
export var UvxZenerLedTestEvt_Evt_Which;
(function (UvxZenerLedTestEvt_Evt_Which) {
    UvxZenerLedTestEvt_Evt_Which[UvxZenerLedTestEvt_Evt_Which["RED_ENABLED"] = 0] = "RED_ENABLED";
    UvxZenerLedTestEvt_Evt_Which[UvxZenerLedTestEvt_Evt_Which["GREEN_ENABLED"] = 1] = "GREEN_ENABLED";
    UvxZenerLedTestEvt_Evt_Which[UvxZenerLedTestEvt_Evt_Which["BLUE_ENABLED"] = 2] = "BLUE_ENABLED";
})(UvxZenerLedTestEvt_Evt_Which || (UvxZenerLedTestEvt_Evt_Which = {}));
export class UvxZenerLedTestEvt_Evt extends __S {
    isRedEnabled() { return __S.getUint16(0, this) === 0; }
    setRedEnabled() { __S.setUint16(0, 0, this); }
    isGreenEnabled() { return __S.getUint16(0, this) === 1; }
    setGreenEnabled() { __S.setUint16(0, 1, this); }
    isBlueEnabled() { return __S.getUint16(0, this) === 2; }
    setBlueEnabled() { __S.setUint16(0, 2, this); }
    toString() { return "UvxZenerLedTestEvt_Evt_" + super.toString(); }
    which() { return __S.getUint16(0, this); }
}
UvxZenerLedTestEvt_Evt.RED_ENABLED = UvxZenerLedTestEvt_Evt_Which.RED_ENABLED;
UvxZenerLedTestEvt_Evt.GREEN_ENABLED = UvxZenerLedTestEvt_Evt_Which.GREEN_ENABLED;
UvxZenerLedTestEvt_Evt.BLUE_ENABLED = UvxZenerLedTestEvt_Evt_Which.BLUE_ENABLED;
UvxZenerLedTestEvt_Evt._capnp = { displayName: "evt", id: "899d60348f43910e", size: new __O(8, 0) };
export class UvxZenerLedTestEvt extends __S {
    getEvt() { return __S.getAs(UvxZenerLedTestEvt_Evt, this); }
    initEvt() { return __S.getAs(UvxZenerLedTestEvt_Evt, this); }
    toString() { return "UvxZenerLedTestEvt_" + super.toString(); }
}
UvxZenerLedTestEvt._capnp = { displayName: "UvxZenerLedTestEvt", id: "e0008d8ca37cb76e", size: new __O(8, 0) };
