import * as UvxZenerCapnp from "./capnp/ts_gen/uvx_zener.capnp";

export interface DeviceTag {
  tagId: number;
  ownerId: string;
  label: string;
}

export interface DeviceMetadata {
  deviceId: string;
  name: string | null;
  description: string | null;
  ownerId: string;
  connected: boolean;
  version: string;
  tagIds: number[];
  height: number;
}
export interface ZenerStatus {
  deviceId: string;
  humanDetected: boolean;
  uvDisinfecting: boolean;
  evtTimestamp: Date;
  zenerState: UvxZenerCapnp.UvxZenerState;
}

export interface ZenerControl {
  uvLampEnabled: boolean;
}

export interface ZenerSettings {
  huDeTempThreshDeltaMin?: number;
  huDeTempThreshDeltaMax?: number;
}

export interface CoredumpMetadata {
  deviceId: string;
  coredumpId: string;
  coredumpLen: number;
  createdAt: Date;
}

export interface FwVersionHistEntry {
  version: string;
  count: number;
}

export interface FwMetadata {
  version: string;
  size: number;
  tagsFilter?: number[];
}

export interface DeviceConnEvt {
  device_id: string;
  connected: boolean;
  evt_ts: Date;
}

export enum ColQueryOp {
  Eq = "eq",
  FuzzyEq = "fuzzy_eq",
  Gt = "gt",
  Ln = "ln",
  Overlap = "overlap",
}

export interface ColQuery<T> {
  value: T | T[];
  op: ColQueryOp;
}

export type DbQuery<T> = {
  [K in keyof T]?: ColQuery<T[K]>;
};
