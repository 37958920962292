import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

import { DeviceMetadata } from "../../types";

import "./DeviceAttributeEditor.scss";
import { DeviceNetUtils } from "../../networking";

export interface DeviceAttributeEditorProps {
  device: DeviceMetadata;
}
export default function DeviceAttributeEditor(
  props: DeviceAttributeEditorProps,
) {
  const { device } = props;

  const [ceilHeight, setCeilHeight] = useState<string>(String(device.height));

  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmit() {
    try {
      const ceilHeightNum = Number.parseFloat(ceilHeight);
      if (Number.isNaN(ceilHeightNum)) {
        throw new Error("Invalid number");
      }

      await DeviceNetUtils.postDevice(device.deviceId, ceilHeightNum);

      enqueueSnackbar("Updated height", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      console.error(error);
    }
  }

  return (
    <Paper className="device-attribute-editor">
      <div className="ceil-height-row">
        <TextField
          className="ceil-height-input"
          fullWidth
          type="number"
          label="Ceiling Height (m)"
          value={ceilHeight}
          onChange={(evt) => setCeilHeight(evt.target.value)}
          contentEditable={false}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </Paper>
  );
}
