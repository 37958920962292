import React from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import withAuth from "../../hocs/withAuth";

import FleetOverview from "../FleetOverview";
import Coredumps from "../Coredumps";
import DeviceDetails from "../DeviceDetails";
import Ota from "../Ota";

import "./App.scss";

export enum PageUrl {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  FleetOverview = "/",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Coredumps = "/coredumps",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  DeviceDetails = "/device-details",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Ota = "/ota",
}

function Nav(): React.ReactElement {
  const location = useLocation();
  return (
    <div className="navbar">
      <h2 className="title">Fleet Manager</h2>
      <div className="nav-links">
        <Link
          className={classnames(
            "nav-link",
            location.pathname === PageUrl.FleetOverview && "active",
          )}
          to={PageUrl.FleetOverview}
        >
          Fleet Overview
        </Link>
        <Link
          className={classnames(
            "nav-link",
            location.pathname === PageUrl.Ota && "active",
          )}
          to={PageUrl.Ota}
        >
          OTA
        </Link>
        <Link
          className={classnames(
            "nav-link",
            location.pathname === PageUrl.Coredumps && "active",
          )}
          to={PageUrl.Coredumps}
        >
          Coredumps
        </Link>
      </div>
    </div>
  );
}

function App(): React.ReactElement {
  return (
    <div className="app">
      <Nav />
      <Routes>
        <Route path={PageUrl.FleetOverview} element={<FleetOverview />} />
        <Route path={PageUrl.Coredumps} element={<Coredumps />} />
        <Route path={PageUrl.DeviceDetails} element={<DeviceDetails />} />
        <Route path={PageUrl.Ota} element={<Ota />} />
      </Routes>
    </div>
  );
}

export default withAuth(App);
