/* tslint:disable */
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
export const _capnpFileId = BigInt("0xc9d479bacc82ccd4");
export var OptionalBool;
(function (OptionalBool) {
    OptionalBool[OptionalBool["NONE"] = 0] = "NONE";
    OptionalBool[OptionalBool["TRUE"] = 1] = "TRUE";
    OptionalBool[OptionalBool["FALSE"] = 2] = "FALSE";
})(OptionalBool || (OptionalBool = {}));
export var OptionalLampOpMode;
(function (OptionalLampOpMode) {
    OptionalLampOpMode[OptionalLampOpMode["NONE"] = 0] = "NONE";
    OptionalLampOpMode[OptionalLampOpMode["OCC"] = 1] = "OCC";
    OptionalLampOpMode[OptionalLampOpMode["NO_OCC"] = 2] = "NO_OCC";
})(OptionalLampOpMode || (OptionalLampOpMode = {}));
export var UvxMcuTy;
(function (UvxMcuTy) {
    UvxMcuTy[UvxMcuTy["ESP32C3"] = 0] = "ESP32C3";
})(UvxMcuTy || (UvxMcuTy = {}));
export var UvxLampTy;
(function (UvxLampTy) {
    UvxLampTy[UvxLampTy["B1"] = 0] = "B1";
    UvxLampTy[UvxLampTy["B1P5"] = 1] = "B1P5";
})(UvxLampTy || (UvxLampTy = {}));
export class UvxFwVersion extends __S {
    getYear() { return __S.getInt32(0, this); }
    setYear(value) { __S.setInt32(0, value, this); }
    getMonth() { return __S.getInt32(4, this); }
    setMonth(value) { __S.setInt32(4, value, this); }
    getDay() { return __S.getInt32(8, this); }
    setDay(value) { __S.setInt32(8, value, this); }
    getCount() { return __S.getInt32(12, this); }
    setCount(value) { __S.setInt32(12, value, this); }
    toString() { return "UvxFwVersion_" + super.toString(); }
}
UvxFwVersion._capnp = { displayName: "UvxFwVersion", id: "9ceb3f5c627f6def", size: new __O(16, 0) };
export class UvxDeviceMetadata extends __S {
    adoptVersion(value) { __S.adopt(value, __S.getPointer(0, this)); }
    disownVersion() { return __S.disown(this.getVersion()); }
    getVersion() { return __S.getStruct(0, UvxFwVersion, this); }
    hasVersion() { return !__S.isNull(__S.getPointer(0, this)); }
    initVersion() { return __S.initStructAt(0, UvxFwVersion, this); }
    setVersion(value) { __S.copyFrom(value, __S.getPointer(0, this)); }
    getMcuTy() { return __S.getUint16(0, this); }
    setMcuTy(value) { __S.setUint16(0, value, this); }
    getLampTy() { return __S.getUint16(2, this); }
    setLampTy(value) { __S.setUint16(2, value, this); }
    toString() { return "UvxDeviceMetadata_" + super.toString(); }
}
UvxDeviceMetadata._capnp = { displayName: "UvxDeviceMetadata", id: "9202799d65f3b447", size: new __O(8, 1) };
export class UvxApmMetrics extends __S {
    getFreeHeapSize() { return __S.getInt32(0, this); }
    setFreeHeapSize(value) { __S.setInt32(0, value, this); }
    getMinFreeHeapSize() { return __S.getInt32(4, this); }
    setMinFreeHeapSize(value) { __S.setInt32(4, value, this); }
    getLargestFreeBlock() { return __S.getInt32(8, this); }
    setLargestFreeBlock(value) { __S.setInt32(8, value, this); }
    getRssiStrength() { return __S.getInt32(12, this); }
    setRssiStrength(value) { __S.setInt32(12, value, this); }
    getTempCelsius() { return __S.getFloat32(16, this); }
    setTempCelsius(value) { __S.setFloat32(16, value, this); }
    getUvLampCommErrs() { return __S.getUint16(20, this); }
    setUvLampCommErrs(value) { __S.setUint16(20, value, this); }
    getRadarCommErrs() { return __S.getUint16(22, this); }
    setRadarCommErrs(value) { __S.setUint16(22, value, this); }
    toString() { return "UvxApmMetrics_" + super.toString(); }
}
UvxApmMetrics._capnp = { displayName: "UvxApmMetrics", id: "df114f4e25a8e44a", size: new __O(24, 0) };
export class UvxWifiCreds_Creds_WpaPersonal extends __S {
    getPass() { return __S.getText(1, this); }
    setPass(value) { __S.setText(1, value, this); }
    toString() { return "UvxWifiCreds_Creds_WpaPersonal_" + super.toString(); }
}
UvxWifiCreds_Creds_WpaPersonal._capnp = { displayName: "wpaPersonal", id: "fb4840a50869fac9", size: new __O(8, 3) };
export class UvxWifiCreds_Creds_WpaEntPeap extends __S {
    getUsername() { return __S.getText(1, this); }
    setUsername(value) { __S.setText(1, value, this); }
    getPassword() { return __S.getText(2, this); }
    setPassword(value) { __S.setText(2, value, this); }
    toString() { return "UvxWifiCreds_Creds_WpaEntPeap_" + super.toString(); }
}
UvxWifiCreds_Creds_WpaEntPeap._capnp = { displayName: "wpaEntPeap", id: "92b0ef8aa6656072", size: new __O(8, 3) };
export var UvxWifiCreds_Creds_Which;
(function (UvxWifiCreds_Creds_Which) {
    UvxWifiCreds_Creds_Which[UvxWifiCreds_Creds_Which["WPA_PERSONAL"] = 0] = "WPA_PERSONAL";
    UvxWifiCreds_Creds_Which[UvxWifiCreds_Creds_Which["WPA_ENT_PEAP"] = 1] = "WPA_ENT_PEAP";
})(UvxWifiCreds_Creds_Which || (UvxWifiCreds_Creds_Which = {}));
export class UvxWifiCreds_Creds extends __S {
    getWpaPersonal() {
        __S.testWhich("wpaPersonal", __S.getUint16(0, this), 0, this);
        return __S.getAs(UvxWifiCreds_Creds_WpaPersonal, this);
    }
    initWpaPersonal() {
        __S.setUint16(0, 0, this);
        return __S.getAs(UvxWifiCreds_Creds_WpaPersonal, this);
    }
    isWpaPersonal() { return __S.getUint16(0, this) === 0; }
    setWpaPersonal() { __S.setUint16(0, 0, this); }
    getWpaEntPeap() {
        __S.testWhich("wpaEntPeap", __S.getUint16(0, this), 1, this);
        return __S.getAs(UvxWifiCreds_Creds_WpaEntPeap, this);
    }
    initWpaEntPeap() {
        __S.setUint16(0, 1, this);
        return __S.getAs(UvxWifiCreds_Creds_WpaEntPeap, this);
    }
    isWpaEntPeap() { return __S.getUint16(0, this) === 1; }
    setWpaEntPeap() { __S.setUint16(0, 1, this); }
    toString() { return "UvxWifiCreds_Creds_" + super.toString(); }
    which() { return __S.getUint16(0, this); }
}
UvxWifiCreds_Creds.WPA_PERSONAL = UvxWifiCreds_Creds_Which.WPA_PERSONAL;
UvxWifiCreds_Creds.WPA_ENT_PEAP = UvxWifiCreds_Creds_Which.WPA_ENT_PEAP;
UvxWifiCreds_Creds._capnp = { displayName: "creds", id: "9921126936efc7db", size: new __O(8, 3) };
export class UvxWifiCreds extends __S {
    getSsid() { return __S.getText(0, this); }
    setSsid(value) { __S.setText(0, value, this); }
    getCreds() { return __S.getAs(UvxWifiCreds_Creds, this); }
    initCreds() { return __S.getAs(UvxWifiCreds_Creds, this); }
    toString() { return "UvxWifiCreds_" + super.toString(); }
}
UvxWifiCreds._capnp = { displayName: "UvxWifiCreds", id: "d9c3b5bc1f7da48d", size: new __O(8, 3) };
export var UvxWifiConnStatus;
(function (UvxWifiConnStatus) {
    UvxWifiConnStatus[UvxWifiConnStatus["DISCONNECTED"] = 0] = "DISCONNECTED";
    UvxWifiConnStatus[UvxWifiConnStatus["CONNECTING"] = 1] = "CONNECTING";
    UvxWifiConnStatus[UvxWifiConnStatus["CONNECTED"] = 2] = "CONNECTED";
})(UvxWifiConnStatus || (UvxWifiConnStatus = {}));
export var UvxProvisionError;
(function (UvxProvisionError) {
    UvxProvisionError[UvxProvisionError["AP_NOT_FOUND"] = 0] = "AP_NOT_FOUND";
    UvxProvisionError[UvxProvisionError["INVALID_CREDS"] = 1] = "INVALID_CREDS";
})(UvxProvisionError || (UvxProvisionError = {}));
export var UvxProvisionEvt_Evt_Which;
(function (UvxProvisionEvt_Evt_Which) {
    UvxProvisionEvt_Evt_Which[UvxProvisionEvt_Evt_Which["CONNECTED_TO_WI_FI"] = 0] = "CONNECTED_TO_WI_FI";
    UvxProvisionEvt_Evt_Which[UvxProvisionEvt_Evt_Which["CONNECTED_TO_SERVER"] = 1] = "CONNECTED_TO_SERVER";
    UvxProvisionEvt_Evt_Which[UvxProvisionEvt_Evt_Which["ERROR"] = 2] = "ERROR";
})(UvxProvisionEvt_Evt_Which || (UvxProvisionEvt_Evt_Which = {}));
export class UvxProvisionEvt_Evt extends __S {
    isConnectedToWiFi() { return __S.getUint16(0, this) === 0; }
    setConnectedToWiFi() { __S.setUint16(0, 0, this); }
    isConnectedToServer() { return __S.getUint16(0, this) === 1; }
    setConnectedToServer() { __S.setUint16(0, 1, this); }
    getError() {
        __S.testWhich("error", __S.getUint16(0, this), 2, this);
        return __S.getUint16(2, this);
    }
    isError() { return __S.getUint16(0, this) === 2; }
    setError(value) {
        __S.setUint16(0, 2, this);
        __S.setUint16(2, value, this);
    }
    toString() { return "UvxProvisionEvt_Evt_" + super.toString(); }
    which() { return __S.getUint16(0, this); }
}
UvxProvisionEvt_Evt.CONNECTED_TO_WI_FI = UvxProvisionEvt_Evt_Which.CONNECTED_TO_WI_FI;
UvxProvisionEvt_Evt.CONNECTED_TO_SERVER = UvxProvisionEvt_Evt_Which.CONNECTED_TO_SERVER;
UvxProvisionEvt_Evt.ERROR = UvxProvisionEvt_Evt_Which.ERROR;
UvxProvisionEvt_Evt._capnp = { displayName: "evt", id: "c631956418562b49", size: new __O(8, 0) };
export class UvxProvisionEvt extends __S {
    getEvt() { return __S.getAs(UvxProvisionEvt_Evt, this); }
    initEvt() { return __S.getAs(UvxProvisionEvt_Evt, this); }
    toString() { return "UvxProvisionEvt_" + super.toString(); }
}
UvxProvisionEvt._capnp = { displayName: "UvxProvisionEvt", id: "fdf5d6fe37309785", size: new __O(8, 0) };
export class UvxWifiStatus extends __S {
    getConnStatus() { return __S.getUint16(0, this); }
    setConnStatus(value) { __S.setUint16(0, value, this); }
    getConnSsid() { return __S.getText(0, this); }
    setConnSsid(value) { __S.setText(0, value, this); }
    toString() { return "UvxWifiStatus_" + super.toString(); }
}
UvxWifiStatus._capnp = { displayName: "UvxWifiStatus", id: "a9754023dabc5df5", size: new __O(8, 1) };
