import React from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import classnames from "classnames";

import "./Loading.css";

interface LoadingProps {
  children: React.ReactElement | React.ReactElement[] | null;
  loading?: boolean;
  loadingClassName?: string;
}
export default function Loading(props: LoadingProps): React.ReactElement {
  const { loading, children, loadingClassName } = props;
  return (
    <div className="loading">
      {loading ? (
        <div className={classnames("loading-wrapper", loadingClassName)}>
          <PacmanLoader color="#201254" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}
Loading.defaultProps = {
  loading: false,
  loadingClassName: undefined,
};
