import { Auth } from "aws-amplify";
import Axios, { InternalAxiosRequestConfig } from "axios";

if (process.env.REACT_APP_API_URL === undefined) {
  // eslint-disable-next-line no-console
  console.error("REACT_APP_API_URL not defined");
}

const instance = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

async function getAccessToken(): Promise<string | null> {
  try {
    const resp = await Auth.currentSession();
    const accessToken = resp.getAccessToken();
    return accessToken.getJwtToken();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
}

instance.interceptors.request.use(
  async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const accessToken = await getAccessToken();

    if (accessToken === null) {
      return config;
    }

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  }
);

export default instance;
