/* tslint:disable */
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
import { UvxFwVersion } from "./uvx.capnp.js";
export const _capnpFileId = BigInt("0xc866f5e54efbe8e9");
export class UvxOtaFwMetadata extends __S {
    adoptVersion(value) { __S.adopt(value, __S.getPointer(0, this)); }
    disownVersion() { return __S.disown(this.getVersion()); }
    getVersion() { return __S.getStruct(0, UvxFwVersion, this); }
    hasVersion() { return !__S.isNull(__S.getPointer(0, this)); }
    initVersion() { return __S.initStructAt(0, UvxFwVersion, this); }
    setVersion(value) { __S.copyFrom(value, __S.getPointer(0, this)); }
    getSize() { return __S.getInt32(0, this); }
    setSize(value) { __S.setInt32(0, value, this); }
    getMcuTy() { return __S.getUint16(4, this); }
    setMcuTy(value) { __S.setUint16(4, value, this); }
    toString() { return "UvxOtaFwMetadata_" + super.toString(); }
}
UvxOtaFwMetadata._capnp = { displayName: "UvxOtaFwMetadata", id: "a249b6726b03e11f", size: new __O(8, 1) };
export class UvxOtaFwChunkReq extends __S {
    getChunkId() { return __S.getUint32(0, this); }
    setChunkId(value) { __S.setUint32(0, value, this); }
    adoptVersion(value) { __S.adopt(value, __S.getPointer(0, this)); }
    disownVersion() { return __S.disown(this.getVersion()); }
    getVersion() { return __S.getStruct(0, UvxFwVersion, this); }
    hasVersion() { return !__S.isNull(__S.getPointer(0, this)); }
    initVersion() { return __S.initStructAt(0, UvxFwVersion, this); }
    setVersion(value) { __S.copyFrom(value, __S.getPointer(0, this)); }
    toString() { return "UvxOtaFwChunkReq_" + super.toString(); }
}
UvxOtaFwChunkReq._capnp = { displayName: "UvxOtaFwChunkReq", id: "d957bee8bdead926", size: new __O(8, 1) };
