import React, { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import dateFmt from "date-fns/format";
import Axios, { CancelTokenSource } from "axios";
import { useSnackbar } from "notistack";

import { AdminNetUtils } from "../../networking";
import { CoredumpMetadata } from "../../types";

import "./Coredumps.css";

const DATE_FMT = "PPPPpppp";

export interface CoredumpsProps {
  deviceId: string;
}
export default function Coredumps(props: CoredumpsProps): React.ReactElement {
  const { deviceId } = props;
  const [coredumps, setCoredumps] = useState<CoredumpMetadata[]>([]);
  const [coredumpsCount, setCoredumpsCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const fetchCancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const fetchCountCancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  function cancelRequest(
    cts: React.MutableRefObject<CancelTokenSource | null>
  ) {
    if (cts.current !== null) {
      cts.current.cancel();
    }
    cts.current = Axios.CancelToken.source();
  }

  async function fetchCoredumps() {
    cancelRequest(fetchCancelTokenSourceRef);
    cancelRequest(fetchCountCancelTokenSourceRef);

    try {
      const nextCoredumps = await AdminNetUtils.getCoredumps(
        deviceId,
        page,
        rowsPerPage,
        fetchCancelTokenSourceRef.current
      );
      const nextCoredumpsCount = await AdminNetUtils.getCoredumpsCount(
        deviceId,
        fetchCancelTokenSourceRef.current
      );
      if (nextCoredumps === null) {
        return;
      }
      setCoredumps(nextCoredumps);
      setCoredumpsCount(nextCoredumpsCount);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  useEffect(() => {
    fetchCoredumps();
  }, [deviceId]);

  return (
    <Paper classes={{ root: "coredumps" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Coredump ID</TableCell>
              <TableCell align="right">Coredump Length</TableCell>
              <TableCell align="right">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coredumps.map((coredump) => (
              <TableRow
                classes={{ root: "coredump-row" }}
                key={coredump.coredumpId}
                hover
                onClick={() =>
                  AdminNetUtils.getCoredump(deviceId, coredump.coredumpId)
                }
                role="button"
              >
                <TableCell>{coredump.coredumpId}</TableCell>
                <TableCell align="right">{coredump.coredumpLen}</TableCell>
                <TableCell align="right">
                  {dateFmt(coredump.createdAt, DATE_FMT)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        classes={{ root: "fleet-overview-pagination" }}
        rowsPerPageOptions={[5, 10, 25]}
        count={coredumpsCount}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(evt) =>
          setRowsPerPage(parseInt(evt.target.value, 10))
        }
        page={page}
        onPageChange={(evt, nextPage) => setPage(nextPage)}
      />
    </Paper>
  );
}
