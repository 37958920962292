import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import dateFmtISO from "date-fns/formatISO";
import dateParseISO from "date-fns/parseISO";
import axios from "./axios";
import { ZenerStatus, ZenerControl } from "../types";

const ZENER_STATUSES_URL = "/zener/statuses";
const ZENER_HISTORICAL_STATUS_URL = "/zener/historical-status";
const ZENER_CONTROL_URL = "/zener/control";

export async function getStatuses(
  deviceIds: string[],
  cancelTokenSource?: CancelTokenSource | null
): Promise<ZenerStatus[]> {
  try {
    const resp: AxiosResponse = await axios.post(
      ZENER_STATUSES_URL,
      { device_ids: deviceIds },
      { cancelToken: cancelTokenSource?.token }
    );

    const { data } = resp;
    const statuses: ZenerStatus[] = data
      .filter((s: any) => s !== null) // eslint-disable-line @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((s: any) => {
        const status: ZenerStatus = {
          deviceId: s.device_id,
          humanDetected: s.human_detected,
          uvDisinfecting: s.uv_lamp_firing,
          zenerState: s.zener_state,
          evtTimestamp: dateParseISO(s.evt_timestamp),
        };
        return status;
      });
    return statuses;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function getHistoricalStatus(
  deviceId: string,
  startDate: Date,
  endDate: Date,
  cancelTokenSource?: CancelTokenSource
): Promise<ZenerStatus[] | null> {
  try {
    const resp: AxiosResponse = await axios.get(ZENER_HISTORICAL_STATUS_URL, {
      params: {
        device_id: deviceId,
        from: dateFmtISO(startDate),
        to: dateFmtISO(endDate),
      },
      cancelToken: cancelTokenSource?.token,
    });

    const { data } = resp;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const statuses: ZenerStatus[] = data.map((sRaw: any) => ({
      humanDetected: sRaw.humanDetected,
      uvDisinfecting: sRaw.uvLampFiring,
      evtTimestamp: dateParseISO(sRaw.evtTimestamp),
      zenerState: sRaw.zenerState,
    }));

    return statuses;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function getControl(
  deviceId: string,
  cancelTokenSource?: CancelTokenSource
): Promise<ZenerControl | null> {
  try {
    const resp: AxiosResponse = await axios.get(ZENER_CONTROL_URL, {
      params: { device_id: deviceId },
      cancelToken: cancelTokenSource?.token,
    });

    const { data } = resp;

    const control: ZenerControl = {
      uvLampEnabled: data.uv_lamp_enabled,
    };
    return control;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function postControl(
  deviceId: string,
  control: Partial<Pick<ZenerControl, "uvLampEnabled">>,
  cancelTokenSource?: CancelTokenSource
): Promise<AxiosResponse | null> {
  try {
    const payload = {
      device_id: deviceId,
      uv_lamp_enabled: control.uvLampEnabled,
    };

    return await axios.post(ZENER_CONTROL_URL, payload, {
      cancelToken: cancelTokenSource?.token,
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}
