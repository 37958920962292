import React, { useEffect, useState, useMemo } from "react";
import { Portal } from "react-portal";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fuse from "fuse.js";
import { useSnackbar } from "notistack";

import CreateTagModal from "../CreateTagModal";
import { TagsNetUtils } from "../../networking";
import { DeviceTag } from "../../types";

import "./ManageTagsModal.scss";

interface FocusedTag {
  anchorEl: EventTarget & HTMLButtonElement;
  tag: DeviceTag;
}

const fuseOptions = {
  keys: ["label"],
};

export interface ManageTagsModalProps {
  open: boolean;
  tags: DeviceTag[];
  initSelectedTagIds?: number[];
  setOpen: (nextOpen: boolean) => void;
  onTagsModified?: () => Promise<void> | void;
  onTagsAssigned?: (tagIds: number[]) => Promise<void> | void;
}
export default function ManageTagsModal(props: ManageTagsModalProps) {
  const {
    open,
    setOpen,
    onTagsModified,
    onTagsAssigned,
    tags,
    initSelectedTagIds = [],
  } = props;

  const [actionMenuFocusedFwMetadata, setActionMenuFocusedFwMetadata] =
    useState<FocusedTag | null>(null);
  const [createTagModelOpen, setCreateTagModelOpen] = useState<boolean>(false);
  const [labelQuery, setLabelQuery] = useState<string>("");
  const [selectedTagIds, setSelectedTagIds] = useState<Set<number>>(new Set());
  const [fuse, setFuse] = useState<Fuse<DeviceTag>>(new Fuse([], fuseOptions));

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSelectedTagIds(new Set(initSelectedTagIds));
  }, [initSelectedTagIds]);

  function handleActionMenuClick(tag: DeviceTag) {
    return function (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      setActionMenuFocusedFwMetadata({
        anchorEl: evt.currentTarget,
        tag,
      });
    };
  }

  function handleActionMenuClose() {
    setActionMenuFocusedFwMetadata(null);
  }

  function closeModal() {
    setSelectedTagIds(new Set());
    setOpen(false);
  }

  async function deleteTagHdlr(tagId: number) {
    try {
      await TagsNetUtils.deleteTags([tagId]);
      enqueueSnackbar("Deleted tag", { variant: "success" });
      if (onTagsModified) {
        await onTagsModified();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function assignTags() {
    if (onTagsAssigned !== undefined) {
      await onTagsAssigned(Array.from(selectedTagIds));
    }
  }

  useEffect(() => {
    setFuse(new Fuse(tags || [], fuseOptions));
  }, [tags]);

  const filteredTags: DeviceTag[] = useMemo(() => {
    if (labelQuery === "") {
      return tags;
    }
    return fuse.search(labelQuery).map((r) => r.item);
  }, [tags, fuse, labelQuery]);

  function labelQueryChangeHdlr(e: React.ChangeEvent<HTMLInputElement>) {
    setLabelQuery(e.target.value);
  }

  function tagCheckboxChangeHdlr(tagId: number) {
    return function (evt: React.ChangeEvent<HTMLInputElement>) {
      setSelectedTagIds((prev: Set<number>) => {
        const next = new Set(prev);

        if (evt.target.checked) {
          next.add(tagId);
        } else {
          next.delete(tagId);
        }

        return next;
      });
    };
  }

  function tagCheckboxChangeAllHdlr() {
    setSelectedTagIds((prev: Set<number>) => {
      const noneSelected = prev.size === 0;

      if (noneSelected) {
        return new Set(tags.map((t) => t.tagId));
      } else {
        return new Set();
      }
    });
  }

  if (!open) {
    return null;
  }

  const numSelected = selectedTagIds.size;
  const numTags = tags.length;

  return (
    <Portal>
      <div className="tag-search-modal-container">
        <div className="background" onClick={() => closeModal()} />
        <Paper classes={{ root: "content" }}>
          <h3 className="header">Tag Search</h3>
          <TextField
            value={labelQuery}
            onChange={labelQueryChangeHdlr}
            placeholder="Filter labels..."
            variant="standard"
          />
          <TableContainer className="tag-table-container">
            <Table stickyHeader className="tag-table">
              <TableHead>
                <TableRow>
                  {onTagsAssigned && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={numTags > 0 && numSelected === numTags}
                        indeterminate={numSelected > 0 && numSelected < numTags}
                        onChange={tagCheckboxChangeAllHdlr}
                      />
                    </TableCell>
                  )}
                  <TableCell>Tag ID</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Owner ID</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTags.map((t) => (
                  <TableRow key={t.tagId}>
                    {onTagsAssigned && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          onChange={tagCheckboxChangeHdlr(t.tagId)}
                          checked={selectedTagIds.has(t.tagId)}
                        />
                      </TableCell>
                    )}
                    <TableCell>{t.tagId}</TableCell>
                    <TableCell>{t.label}</TableCell>
                    <TableCell>{t.ownerId}</TableCell>
                    <TableCell classes={{ root: "menu-col" }}>
                      <IconButton onClick={handleActionMenuClick(t)}>
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="action-row">
            <Button onClick={() => closeModal()}>Close</Button>
            <Button
              color="secondary"
              onClick={() => setCreateTagModelOpen(true)}
            >
              Create
            </Button>
            <Button variant="contained" color="primary" onClick={assignTags}>
              Assign
            </Button>
          </div>
        </Paper>
      </div>
      <CreateTagModal
        open={createTagModelOpen}
        setOpen={setCreateTagModelOpen}
        onTagCreated={onTagsModified}
      />
      <Menu
        anchorEl={actionMenuFocusedFwMetadata?.anchorEl}
        keepMounted
        open={actionMenuFocusedFwMetadata !== null}
        onClose={handleActionMenuClose}
      >
        <MenuItem
          onClick={() => deleteTagHdlr(actionMenuFocusedFwMetadata.tag.tagId)}
        >
          Delete
        </MenuItem>
      </Menu>
    </Portal>
  );
}
