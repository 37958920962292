import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import axios from "./axios";

import * as Utils from "../util";
import { DeviceTag, DbQuery } from "../types";

const TAGS_URL = "/tags";
const QUERY_TAGS_URL = `${TAGS_URL}/query`;
const QUERY_TAGS_COUNT_URL = `${QUERY_TAGS_URL}/count`;

export async function getTags(
  tagQuery: DbQuery<DeviceTag> = {},
  cancelTokenSource?: CancelTokenSource
): Promise<DeviceTag[]> {
  try {
    const resp: AxiosResponse = await axios.post(QUERY_TAGS_URL, tagQuery, {
      cancelToken: cancelTokenSource?.token,
    });

    const tags: DeviceTag[] = Utils.keysToCamelCase(resp.data);
    return tags;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return [];
    }

    throw error;
  }
}

export async function getTagsCount(
  tagQuery: DbQuery<DeviceTag> = {},
  cancelTokenSource?: CancelTokenSource
): Promise<number | null> {
  try {
    const resp: AxiosResponse = await axios.post(
      QUERY_TAGS_COUNT_URL,
      tagQuery,
      {
        cancelToken: cancelTokenSource?.token,
      }
    );

    return resp.data?.count || null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return null;
    }

    throw error;
  }
}

export async function deleteTags(tagIds: number[]): Promise<void> {
  await axios.delete(TAGS_URL, {
    params: { tag_ids: tagIds },
  });
}

export interface CreateTagsPayloadItem {
  ownerId?: string;
  label: string;
}
export type CreateTagsPayload = CreateTagsPayloadItem[];
export async function createTags(
  payload: CreateTagsPayload,
  cancelTokenSource?: CancelTokenSource
) {
  try {
    await axios.put(
      TAGS_URL,
      { tags: Utils.keysToSnakeCase(payload) },
      { cancelToken: cancelTokenSource?.token }
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return null;
    }

    throw error;
  }
}
