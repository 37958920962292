import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import { FwVersionHistEntry } from "../../types";

import "./FwVersionHist.scss";

export interface FwVersionHistProps {
  hist: FwVersionHistEntry[];
  count: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}
export default function FwVersionHist(
  props: FwVersionHistProps
): React.ReactElement {
  const { hist, page, setPage, rowsPerPage, setRowsPerPage, count } = props;

  return (
    <TableContainer component={Paper}>
      <Table className="fw-version-hist-table">
        <TableHead>
          <TableRow>
            <TableCell>Version</TableCell>
            <TableCell align="right">Device Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hist.map((row: FwVersionHistEntry) => (
            <TableRow key={row.version}>
              <TableCell>{row.version || "<empty>"}</TableCell>
              <TableCell align="right">{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(evt, next: number) => setPage(next)}
        onRowsPerPageChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          setRowsPerPage(Number.parseInt(evt.target.value, 10))
        }
      />
    </TableContainer>
  );
}
