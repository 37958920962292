import React, { useState, useEffect, useCallback, useMemo } from "react";
import Paper from "@mui/material/Paper";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
  Customized,
  Rectangle,
  Text,
} from "recharts";
import { useSnackbar } from "notistack";
import getUnixTime from "date-fns/getUnixTime";
import dateSecondsToMs from "date-fns/secondsToMilliseconds";
import dateAdd from "date-fns/add";
import dateDiffInMinutes from "date-fns/differenceInMinutes";
import dateIntervalToDuration from "date-fns/intervalToDuration";
import dateFormatDuration from "date-fns/formatDuration";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { ZenerNetUtils } from "../../networking";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { zenerStateCapnpToStr } from "../../util";
import { UvxZenerState } from "../../capnp/ts_gen/uvx_zener.capnp";

import "./StatusGraph.scss";

const HIST_MINUTES = 60;
const GRID_TICKS_PERIOD_MINUTES = 10;

interface ZenerStatusGraphItem {
  humanDetected: number;
  uvDisinfecting: number;
  evtTimestamp: number;
  state: string;
}

function mapZenerStatetoColor(state: string): string {
  switch (state) {
    case "START":
      return "#800000";
    case "INITIALIZING":
      return "#9A6324";
    case "UNCONFIGURED":
      return "#f032e6";
    case "ENABLED":
      return "#3cb44b";
    case "DISABLED":
      return "#42d4f4";
    case "OTA_UPDATING":
      return "#dcbeff";
    case "ERROR":
      return "#e6194B";
    case "RESTART":
      return "#ffe119";
    case "DISCONNECTED":
      return "#676767";
    case "INVALID":
      return "#f58231";
  }
}

interface StatusGraphStateSeriesProps {
  data: ZenerStatusGraphItem[];
  xAxisMap?: Record<number, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  yAxisMap?: Record<number, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}
function StatusGraphStateSeries(props: StatusGraphStateSeriesProps) {
  const { data, xAxisMap } = props;

  const xAxis = xAxisMap[0];
  if (xAxis === undefined) {
    return null;
  }
  const scaleX = xAxis.scale;

  const stateDomains: Record<string, [number, number][]> = {};
  let prevState: string | undefined;
  for (let i = 0; i < data.length; i++) {
    const currItem = data[i];
    const currTs = currItem.evtTimestamp;
    const currState = currItem.state;

    if (prevState === currState) {
      // Update existing domain.
      const domains = stateDomains[currState];
      const domain = domains[domains.length - 1];
      domain[0] = currTs;
    } else {
      // Create new domain.
      if (stateDomains[currState] === undefined) {
        stateDomains[currState] = [];
      }

      const currDomains = stateDomains[currState];

      // Update previous domain.
      if (prevState !== undefined) {
        const prevDomains = stateDomains[prevState];
        const prevDomain = prevDomains[prevDomains.length - 1];
        prevDomain[0] = currTs;
      }

      const newDomain: [number, number] = [currTs, currTs];
      currDomains.push(newDomain);
    }

    prevState = currState;
  }

  return Object.entries(stateDomains).flatMap(
    ([state, domains]: [string, [number, number][]]) =>
      domains.map(([dL, dR]: [number, number]) => {
        const key = `${state}-${dL}-${dR}`;
        const width = Math.abs(scaleX(dR) - scaleX(dL));
        const x = scaleX(dL) - width;
        const y = 150;
        const height = 40;
        const color = mapZenerStatetoColor(state);

        const xC = x + width / 2;
        const yC = y + height / 2;

        return (
          <>
            <Rectangle
              className="status-bar"
              key={key}
              x={x}
              y={y}
              width={width}
              height={height}
              fill={color}
            />
            {width > 10 && (
              <Text
                fill="white"
                x={xC}
                y={yC}
                offset={0}
                textAnchor="middle"
                verticalAnchor="middle"
              >
                {state}
              </Text>
            )}
          </>
        );
      }),
  );
}

interface StatusGraphTooltipProps {
  active?: boolean;
  payload?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  label?: number;
  endDate: Date;
}

function StatusGraphTooltip(props: StatusGraphTooltipProps) {
  const { active, payload, label, endDate } = props;
  if (
    !active ||
    label === undefined ||
    !Array.isArray(payload) ||
    payload.length === 0
  ) {
    return null;
  }

  function mapNameToLabel(name: string): string {
    switch (name) {
      case "uvDisinfecting":
        return "firing";
      case "humanDetected":
        return "occupancy";
    }
  }

  function mapValueToBool(name: string, value: number): string {
    switch (name) {
      case "uvDisinfecting":
        return value > 0.5 ? "T" : "F";
      case "humanDetected":
        return value > 3 ? "T" : "F";
    }
  }

  const xDate = new Date(dateSecondsToMs(label));
  const minsDiff = dateDiffInMinutes(endDate, xDate);

  const occPayload = payload.find((p) => p.name === "humanDetected");
  const occBool =
    occPayload && mapValueToBool(occPayload.name, occPayload.value);

  const firingPayload = payload.find((p) => p.name === "uvDisinfecting");
  const firingBool =
    firingPayload && mapValueToBool(firingPayload.name, firingPayload.value);

  const zenerState =
    occPayload?.payload.state ||
    firingPayload?.payload.state;
  const zenerStateColor = zenerState && mapZenerStatetoColor(zenerState);

  return (
    <div className="tooltip">
      {occPayload !== undefined && (
        <div className="tooltip-row">
          <span style={{ color: occPayload.color }}>
            {mapNameToLabel(occPayload.name)}: &nbsp;
          </span>
          <span style={{ color: occBool === "T" ? "green" : "red" }}>
            {occBool}
          </span>
        </div>
      )}
      {firingPayload !== undefined && (
        <div className="tooltip-row">
          <span style={{ color: firingPayload.color }}>
            {mapNameToLabel(firingPayload.name)}: &nbsp;
          </span>
          <span style={{ color: firingBool === "T" ? "green" : "red" }}>
            {firingBool}
          </span>
        </div>
      )}
      {zenerState !== undefined && (
        <div className="tooltip-row">
          <span>state: &nbsp;</span>
          <span style={{ color: zenerStateColor }}>{zenerState}</span>
        </div>
      )}
      <span>-{minsDiff} mins</span>
    </div>
  );
}

interface FormatDateAxisTickProps {
  x: number;
  y: number;
  payload: Record<string, number>;
  endDate: Date;
}

function FormatDateAxisTick(
  props: FormatDateAxisTickProps,
): React.ReactElement {
  const { x, y, payload, endDate } = props;

  const xDate = new Date(dateSecondsToMs(payload.value));
  const minsDiff = dateDiffInMinutes(endDate, xDate);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={15}>
        <tspan textAnchor="middle" x="0">
          -{minsDiff}
        </tspan>
      </text>
    </g>
  );
}

interface StatusGraphProps {
  deviceId: string;
}
export default function StatusGraph(
  props: StatusGraphProps,
): React.ReactElement {
  const { deviceId } = props;
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [graphData, setGraphData] = useState<ZenerStatusGraphItem[] | null>(
    null,
  );
  const [lastUpdatedStr, setLastUpdatedStr] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  async function fetchStatusData() {
    const startDate = dateAdd(endDate, { minutes: -HIST_MINUTES });

    try {
      const nextHistoricalStatus = await ZenerNetUtils.getHistoricalStatus(
        deviceId,
        startDate,
        endDate,
      );
      if (nextHistoricalStatus === null) {
        enqueueSnackbar("No data found within this range", {
          variant: "error",
        });
        return;
      }

      const nextGraphData = nextHistoricalStatus.map((hs) => ({
        humanDetected: Number(hs.humanDetected) + 3.5,
        uvDisinfecting: Number(hs.uvDisinfecting) + 2.25,
        evtTimestamp: getUnixTime(hs.evtTimestamp),
        state: zenerStateCapnpToStr(hs.zenerState),
      }));

      setGraphData(nextGraphData);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  useEffect(() => {
    fetchStatusData();
  }, [endDate]);

  const vertGridPointsGen = useCallback(
    (props: {
      xAxis: any; // eslint-disable-line @typescript-eslint/no-explicit-any
      width: number;
      height: number;
      offset: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }) => {
      const ticks = props.xAxis.ticks;
      return ticks.map((t: number) => props.xAxis.scale(t));
    },
    [endDate],
  );

  const ticks = useMemo(() => {
    const numLines = Math.round(HIST_MINUTES / GRID_TICKS_PERIOD_MINUTES);
    const res = [];
    for (let i = 0; i < numLines; i++) {
      const tickDate = dateAdd(endDate, {
        minutes: -GRID_TICKS_PERIOD_MINUTES * i,
      });
      res.push(getUnixTime(tickDate));
    }
    return res;
  }, [endDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      const lastUpdatedAtS = graphData.reduce(
        (acc: number, curr: ZenerStatusGraphItem) => {
          if (curr.evtTimestamp > acc) {
            return curr.evtTimestamp;
          }

          return acc;
        },
        -Infinity,
      );

      if (!Number.isFinite(lastUpdatedAtS)) {
        return;
      }

      const lastUpdatedDate = new Date(dateSecondsToMs(lastUpdatedAtS));
      const nowDate = new Date();
      const diffDuration = dateIntervalToDuration({
        start: lastUpdatedDate,
        end: nowDate,
      });
      const nextLastUpdatedStr = dateFormatDuration(diffDuration);
      setLastUpdatedStr(nextLastUpdatedStr);
    }, 1000);

    return () => clearInterval(interval);
  }, [graphData]);

  return (
    <>
      {graphData === null ? (
        <Paper>No Data</Paper>
      ) : (
        <Paper classes={{ root: "status-graph" }}>
          <div className="details-row">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="End Date"
                value={endDate}
                onChange={setEndDate}
                maxDate={new Date()}
              />
            </LocalizationProvider>
            <span className="last-updated-field">
              {lastUpdatedStr !== null && `last updated ${lastUpdatedStr} ago`}
            </span>
          </div>
          <div className="chart-container">
            <div className="trace-labels-container">
              <span>Occupancy</span>
              <span>Firing</span>
            </div>
            <ResponsiveContainer width="85%" height={250}>
              <LineChart
                data={graphData}
                syncId="status"
                margin={{ bottom: 15 }}
              >
                <CartesianGrid
                  horizontal={false}
                  verticalCoordinatesGenerator={vertGridPointsGen}
                />
                <XAxis
                  dataKey="evtTimestamp"
                  domain={["dataMin", "dataMax"]}
                  tick={(props) => (
                    <FormatDateAxisTick {...props} endDate={endDate} />
                  )}
                  ticks={ticks}
                  type="number"
                />
                <YAxis domain={[0, 6]} hide />
                <Tooltip content={<StatusGraphTooltip endDate={endDate} />} />
                <Line
                  type="stepAfter"
                  dataKey="uvDisinfecting"
                  dot={false}
                  strokeWidth={1}
                  stroke="red"
                />
                <Line
                  type="stepAfter"
                  dataKey="humanDetected"
                  dot={false}
                  strokeWidth={1}
                  stroke="blue"
                />
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <Customized component={StatusGraphStateSeries as any} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="state-legend">
            {Object.values(UvxZenerState)
              .filter((v) => typeof v === "string")
              .map((v: string) => (
                <span key={v} style={{ color: mapZenerStatetoColor(v) }}>
                  {v}
                </span>
              ))}
          </div>
        </Paper>
      )}
    </>
  );
}
