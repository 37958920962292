import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useDropzone } from "react-dropzone";
import Axios, { CancelTokenSource } from "axios";
import { useSnackbar } from "notistack";

import FwVersionHist from "../../components/FwVersionHist";
import FwVersions from "../../components/FwVersions";
import { AdminNetUtils, TagsNetUtils } from "../../networking";
import { FwVersionHistEntry, FwMetadata, DeviceTag } from "../../types";
import validateSdkConfig, {
  readFile,
  parseSdkConfig,
  config as sdkConfigReqs,
  validateVersion,
} from "../../ota";

import "./Ota.css";

interface FileUploadAreaProps {
  label: string;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  error?: boolean;
}
function FileUploadArea(props: FileUploadAreaProps) {
  const { label, file, setFile, error } = props;

  const handleFileDrop = useCallback(async (newFiles: File[]) => {
    if (newFiles.length !== 1) {
      // eslint-disable-next-line no-console
      console.error("Invalid number of files");
      return;
    }

    setFile(newFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileDrop,
  });

  const message = useMemo(() => {
    if (error) {
      return "Error";
    }

    if (file === null) {
      return label;
    }

    return "File ready";
  }, [error, file, label]);

  let color = "06060633";
  if (error) {
    color = "FF000033";
  } else if (file !== null) {
    color = "00FF0033";
  }

  return (
    <div className="ota-upload">
      <div
        className="ota-upload-area"
        {...getRootProps()}
        style={{
          background: `repeating-linear-gradient(
            45deg,
            #00000000,
            #00000000 10px,
            #${color} 10px,
            #${color} 20px
          `,
        }}
      >
        <input {...getInputProps()} />
        <p>{message}</p>
      </div>
    </div>
  );
}

interface CfgFileUploadAreaProps extends FileUploadAreaProps {
  cfgErrors: string[];
  setCfgErrors: React.Dispatch<React.SetStateAction<string[]>>;
  setVersion: React.Dispatch<React.SetStateAction<string>>;
}
function CfgFileUploadArea(props: CfgFileUploadAreaProps): React.ReactElement {
  const { cfgErrors, setCfgErrors, setVersion, ...other } = props;

  const { enqueueSnackbar } = useSnackbar();

  async function handleFile(file: File) {
    try {
      const content = await readFile(file);
      const sdkConfig = parseSdkConfig(content);

      if (sdkConfig.CONFIG_APP_PROJECT_VER) {
        setVersion(sdkConfig.CONFIG_APP_PROJECT_VER.value);
      } else {
        setCfgErrors(["Unable to parse binary version from config"]);
        return;
      }

      const nextCfgErrors = validateSdkConfig(
        sdkConfig,
        sdkConfigReqs as Record<string, string>
      );
      setCfgErrors(nextCfgErrors);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  useEffect(() => {
    if (other.file === null) {
      return;
    }
    handleFile(other.file);
  }, [other.file]);

  return (
    <div>
      <FileUploadArea error={cfgErrors.length > 0} {...other} />
      {cfgErrors.length > 0 && (
        <div className="ota-binary-cfg-errors">
          {cfgErrors.map((e) => (
            <p key={e}>{e}</p>
          ))}
        </div>
      )}
    </div>
  );
}

export default function Ota(): React.ReactElement {
  const [fwVersionHist, setFwVersionHist] = useState<FwVersionHistEntry[]>([]);
  const [fwVersionHistPage, setFwVersionHistPage] = useState(0);
  const [fwVersionHistRowsPerPage, setFwVersionHistRowsPerPage] = useState(5);
  const [fwVersionHistCount, setFwVersionHistCount] = useState(0);

  const [fwMetadatas, setFwMetadatas] = useState<FwMetadata[]>([]);
  const [fwMetadatasPage, setFwMetadatasPage] = useState(0);
  const [fwMetadatasRowsPerPage, setFwMetadatasRowsPerPage] = useState(5);
  const [fwMetadatasCount, setFwMetadatasCount] = useState(0);

  const [tags, setTags] = useState<DeviceTag[]>([]);

  const [binaryFile, setBinaryFile] = useState<File | null>(null);
  const [cfgFile, setCfgFile] = useState<File | null>(null);
  const [cfgErrors, setCfgErrors] = useState<string[]>([]);
  const [version, setVersion] = useState<string>("");

  const fetchFwVersionHistCTSRef = useRef<CancelTokenSource | null>(null);
  const fetchFwVersionHistCountCTSRef = useRef<CancelTokenSource | null>(null);
  const fetchFwMetadatasCTSRef = useRef<CancelTokenSource | null>(null);
  const fetchFwMetadatasCountCTSRef = useRef<CancelTokenSource | null>(null);
  const fetchTagsCTSRef = useRef<CancelTokenSource | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  function cancelRequest(
    cts: React.MutableRefObject<CancelTokenSource | null>
  ) {
    if (cts.current !== null) {
      cts.current.cancel();
    }
    cts.current = Axios.CancelToken.source();
  }

  async function fetchTags() {
    cancelRequest(fetchTagsCTSRef);
    try {
      const nextTags = await TagsNetUtils.getTags(
        undefined,
        fetchTagsCTSRef.current
      );
      setTags(nextTags);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  useEffect(() => {
    fetchTags();
  }, []);

  async function getFwVersionHist() {
    cancelRequest(fetchFwVersionHistCTSRef);
    cancelRequest(fetchFwVersionHistCountCTSRef);

    try {
      const nextFwVersionHist = await AdminNetUtils.getFirmwareVersionHist(
        fwVersionHistPage * fwVersionHistRowsPerPage,
        fwVersionHistRowsPerPage,
        fetchFwVersionHistCTSRef.current
      );
      if (nextFwVersionHist !== null) {
        setFwVersionHist(nextFwVersionHist);
      }

      const nextFwVersionHistCount =
        await AdminNetUtils.getFirmwareVersionHistCount(
          fetchFwVersionHistCountCTSRef.current
        );
      if (nextFwVersionHistCount !== null) {
        setFwVersionHistCount(nextFwVersionHistCount);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getFwMetadatas() {
    cancelRequest(fetchFwMetadatasCTSRef);
    cancelRequest(fetchFwMetadatasCountCTSRef);

    try {
      const nextFwMetadatas = await AdminNetUtils.getFirmwareMetadatas(
        fwMetadatasPage * fwMetadatasRowsPerPage,
        fwMetadatasRowsPerPage,
        fetchFwMetadatasCTSRef.current
      );
      if (nextFwMetadatas !== null) {
        setFwMetadatas(nextFwMetadatas);
      }

      const nextFwMetadatasCount =
        await AdminNetUtils.getFirmwareMetadatasCount(
          fetchFwMetadatasCountCTSRef.current
        );
      if (nextFwMetadatasCount !== null) {
        setFwMetadatasCount(nextFwMetadatasCount);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  useEffect(() => {
    getFwVersionHist();
  }, [fwVersionHistPage, fwVersionHistRowsPerPage]);

  useEffect(() => {
    getFwMetadatas();
  }, [fwMetadatasPage, fwMetadatasRowsPerPage]);

  useEffect(() => {
    // If there are config errors, we will need to recompile anyway.
    // I don't want us to forget to recompile the binary when we make
    // cfg changes.
    if (cfgErrors.length > 0 && binaryFile !== null) {
      setBinaryFile(null);
    }
  }, [cfgErrors]);

  const versionError = useMemo(() => validateVersion(version), [version]);

  const uploadReady = useMemo(
    () =>
      binaryFile !== null &&
      cfgFile !== null &&
      cfgErrors.length === 0 &&
      versionError === null,
    [binaryFile, cfgFile, cfgErrors.length, versionError]
  );

  async function doUpload() {
    if (binaryFile === null || versionError !== null) {
      return;
    }

    try {
      await AdminNetUtils.putFirmwareBinaryFile(binaryFile, version);
      await getFwMetadatas();
      enqueueSnackbar("Uploaded binary", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function doDeploy(metadata: FwMetadata) {
    try {
      await AdminNetUtils.postNotifyOTA(metadata.version);
      enqueueSnackbar("Fleet notified", { variant: "success" });

      // Clear state.
      setBinaryFile(null);
      setCfgFile(null);
      setCfgErrors([]);
      setVersion("");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function doRollback(fwVersion: string) {
    try {
      await AdminNetUtils.postFirmwareRollback(fwVersion);
      enqueueSnackbar("Successfully performed rollback", {
        variant: "success",
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  return (
    <div className="ota">
      <div className="ota-fleet-status-row">
        <FwVersionHist
          hist={fwVersionHist}
          count={fwVersionHistCount}
          page={fwVersionHistPage}
          setPage={setFwVersionHistPage}
          rowsPerPage={fwVersionHistRowsPerPage}
          setRowsPerPage={setFwVersionHistRowsPerPage}
        />
        <FwVersions
          tags={tags}
          onTagsModified={fetchTags}
          metadatas={fwMetadatas}
          doDeploy={doDeploy}
          doRollback={doRollback}
          onTagsAssigned={getFwMetadatas}
          count={fwMetadatasCount}
          page={fwMetadatasPage}
          setPage={setFwMetadatasPage}
          rowsPerPage={fwMetadatasRowsPerPage}
          setRowsPerPage={setFwMetadatasRowsPerPage}
        />
      </div>
      <Paper className="ota-deploy-area">
        <h2 className="title">Deploy</h2>
        <TextField
          error={version !== "" && versionError !== null}
          helperText={version !== "" && versionError}
          fullWidth
          label="Version"
          value={version}
          contentEditable={false}
        />
        <FileUploadArea
          label="Binary"
          file={binaryFile}
          setFile={setBinaryFile}
        />
        <CfgFileUploadArea
          cfgErrors={cfgErrors}
          setCfgErrors={setCfgErrors}
          setVersion={setVersion}
          label="Config"
          file={cfgFile}
          setFile={setCfgFile}
        />
        <Button
          className="ota-upload-btn"
          fullWidth
          color="primary"
          variant="contained"
          disabled={!uploadReady}
          onClick={doUpload}
        >
          Upload
        </Button>
      </Paper>
    </div>
  );
}
